import { Trans } from '@lingui/macro'
import { Currency, CurrencyAmount, Percent } from '@uniswap/sdk-core'
import { Pair } from '@uniswap/v2-sdk'
import { useWeb3React } from '@web3-react/core'
import { AutoColumn } from 'components/Column'
import { LoadingOpacityContainer, loadingOpacityMixin } from 'components/Loader/styled'
import CurrencyLogo from 'components/Logo/CurrencyLogo'
import { BridgeChainIds, isSupportedChain, SupportedL1ChainId } from 'constants/chains'
import { ethers } from 'ethers'
import { darken } from 'polished'
import { ReactNode, useCallback, useMemo, useState } from 'react'
import { Lock } from 'react-feather'
import styled, { useTheme } from 'styled-components/macro'
import { flexColumnNoWrap, flexRowNoWrap } from 'theme/styles'

import { ReactComponent as DropDown } from '../../assets/images/dropdown.svg'
import { useCurrencyBalance } from '../../state/connection/hooks'
import { ThemedText } from '../../theme'
import { ButtonGray } from '../Button'
import DoubleCurrencyLogo from '../DoubleLogo'
import { Input as NumericalInput } from '../NumericalInput'
import { RowBetween, RowFixed } from '../Row'

const InputPanel = styled.div<{ hideInput?: boolean }>`
  ${flexColumnNoWrap};
  position: relative;
  border-radius: ${({ hideInput }) => (hideInput ? '16px' : '20px')};
  z-index: 1;
  width: ${({ hideInput }) => (hideInput ? '100%' : 'initial')};
  transition: height 1s ease;
  will-change: height;
`

const FixedContainer = styled.div`
  width: 100%;
  height: 100%;
  position: absolute;
  border-radius: 20px;
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 2;
`

const Container = styled.div<{ hideInput: boolean }>`
  min-height: 44px;
  border-radius: ${({ hideInput }) => (hideInput ? '16px' : '20px')};
  width: ${({ hideInput }) => (hideInput ? '100%' : 'initial')};
`

const CurrencySelect = styled(ButtonGray)<{
  visible: boolean
  selected: boolean
  hideInput?: boolean
  disabled?: boolean
}>`
  align-items: center;
  background-color: ${({ selected, theme }) => (selected ? theme.background : theme.accentAction)};
  opacity: ${({ disabled }) => (!disabled ? 1 : 0.4)};
  box-shadow: ${({ selected }) => (selected ? 'none' : '0px 6px 10px rgba(0, 0, 0, 0.075)')};
  color: ${({ selected, theme }) => (selected ? theme.textPrimary : theme.white)};
  cursor: pointer;
  height: unset;
  border-radius: 16px;
  outline: none;
  user-select: none;
  border: none;
  font-size: 24px;
  font-weight: 400;
  width: ${({ hideInput }) => (hideInput ? '100%' : 'initial')};
  padding: ${({ selected }) => (selected ? '8px' : '6px 6px 6px 8px')};
  gap: 6px;
  justify-content: space-between;
  margin-left: ${({ hideInput }) => (hideInput ? '0' : '12px')};

  &:hover,
  &:active {
    background-color: ${({ theme, selected }) => (selected ? theme.backgroundInteractive : theme.accentAction)};
  }

  &:before {
    background-size: 100%;
    border-radius: inherit;

    position: absolute;
    top: 0;
    left: 0;

    width: 100%;
    height: 100%;
    content: '';
  }

  &:hover:before {
    background-color: ${({ theme }) => theme.stateOverlayHover};
  }

  &:active:before {
    background-color: ${({ theme }) => theme.stateOverlayPressed};
  }

  visibility: ${({ visible }) => (visible ? 'visible' : 'hidden')};
`

const InputRow = styled.div`
  ${flexRowNoWrap};
  align-items: center;
  justify-content: space-between;
`

const LabelRow = styled.div`
  ${flexRowNoWrap};
  align-items: center;
  color: ${({ theme }) => theme.textSecondary};
  font-size: 0.75rem;
  line-height: 1rem;

  span:hover {
    cursor: pointer;
    color: ${({ theme }) => darken(0.2, theme.textSecondary)};
  }
`

const FiatRow = styled(LabelRow)`
  justify-content: flex-end;
  min-height: 20px;
  padding: 8px 0px 0px 0px;
`

const Aligner = styled.span`
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;
`

const StyledDropDown = styled(DropDown)<{ selected: boolean }>`
  margin: 0 0.25rem 0 0.35rem;
  height: 35%;
  margin-left: 8px;

  path {
    stroke: ${({ selected, theme }) => (selected ? theme.textPrimary : theme.white)};
    stroke-width: 2px;
  }
`

const StyledTokenName = styled.span<{ active?: boolean }>`
  ${({ active }) => (active ? '  margin: 0 0.25rem 0 0.25rem;' : '  margin: 0 0.25rem 0 0.25rem;')}
  font-size: 20px;
  font-weight: 600;
`

const StyledBalanceMax = styled.button<{ disabled?: boolean }>`
  background-color: transparent;
  border: none;
  color: ${({ theme }) => theme.accentAction};
  cursor: pointer;
  font-size: 14px;
  font-weight: 600;
  opacity: ${({ disabled }) => (!disabled ? 1 : 0.4)};
  padding: 4px 6px;
  pointer-events: ${({ disabled }) => (!disabled ? 'initial' : 'none')};

  :hover {
    opacity: ${({ disabled }) => (!disabled ? 0.8 : 0.4)};
  }

  :focus {
    outline: none;
  }
`

const StyledNumericalInput = styled(NumericalInput)<{ $loading: boolean }>`
  ${loadingOpacityMixin};
  text-align: left;
  font-size: 36px;
  line-height: 44px;
  font-variant: small-caps;
`

interface SwapCurrencyInputPanelProps {
  value: string
  onUserInput: (value: string) => void
  onMax?: () => void
  showMaxButton: boolean
  label?: ReactNode
  onCurrencySelect?: (currency: Currency) => void
  currency?: Currency | null
  hideBalance?: boolean
  pair?: Pair | null
  hideInput?: boolean
  otherCurrency?: Currency | null
  fiatValue?: { data?: number; isLoading: boolean }
  priceImpact?: Percent
  id: string
  showCommonBases?: boolean
  showCurrencyAmount?: boolean
  disableNonToken?: boolean
  renderBalance?: (amount: CurrencyAmount<Currency>) => ReactNode
  locked?: boolean
  loading?: boolean
  disabled?: boolean
  error?: boolean
  balance?: ethers.BigNumber
  fee?: ethers.BigNumber
}

export default function SwapCurrencyInputPanel({
  value,
  onUserInput,
  onMax,
  showMaxButton,
  onCurrencySelect,
  currency,
  otherCurrency,
  id,
  showCommonBases,
  showCurrencyAmount,
  disableNonToken,
  renderBalance,
  fiatValue,
  priceImpact,
  hideBalance = false,
  pair = null, // used for double token logo
  hideInput = false,
  locked = false,
  loading = false,
  disabled = false,
  error = false,
  balance,
  fee,
  ...rest
}: SwapCurrencyInputPanelProps) {
  const [modalOpen, setModalOpen] = useState(false)
  const { account, chainId, provider } = useWeb3React()
  const selectedCurrencyBalance = useCurrencyBalance(account ?? undefined, currency ?? undefined)
  const theme = useTheme()

  const handleDismissSearch = useCallback(() => {
    setModalOpen(false)
  }, [setModalOpen])

  const chainAllowed = isSupportedChain(chainId)

  // console.log('currency11', currency)
  // console.log('pair', pair)
  // console.log('format amount', formatCurrencyAmount(selectedCurrencyBalance, NumberType.TokenNonTx))

  const isPlayDappNetwork = useMemo(() => BridgeChainIds[chainId as SupportedL1ChainId] === 1, [chainId])

  // const [nativeBalance, setNativeBalance] = useState('-1')
  //
  // const nativePDABalance = useMemo(() => {
  //   if (!isPlayDappNetwork) return '-1'
  //
  //   return nativeBalance
  // }, [isPlayDappNetwork, nativeBalance])

  // console.log('nativePDABalance', nativePDABalance)

  // useEffect(() => {
  //   ;(async () => {
  //     if (provider && account) {
  //       const balance = await provider.getBalance(account)
  //       console.log('balance', formatEther(balance))
  //       setNativeBalance(formatEther(balance))
  //     }
  //   })()
  // }, [provider, account])

  // console.log('currency', currency)
  // console.log('currency11', currency)
  return (
    <InputPanel id={id} hideInput={hideInput} {...rest}>
      {locked && (
        <FixedContainer>
          <AutoColumn gap="sm" justify="center">
            <Lock />
            <ThemedText.DeprecatedLabel fontSize="12px" textAlign="center" padding="0 12px">
              <Trans>The market price is outside your specified price range. Single-asset deposit only.</Trans>
            </ThemedText.DeprecatedLabel>
          </AutoColumn>
        </FixedContainer>
      )}
      <Container hideInput={hideInput}>
        <InputRow style={hideInput ? { padding: '0', borderRadius: '8px' } : {}}>
          {!hideInput && (
            <StyledNumericalInput
              error={error}
              className="token-amount-input"
              value={value}
              onUserInput={onUserInput}
              disabled={!chainAllowed || disabled}
              $loading={loading}
            />
          )}

          <CurrencySelect
            disabled={!chainAllowed || disabled}
            visible={currency !== undefined}
            selected={!!currency}
            hideInput={hideInput}
            className="open-currency-select-button"
            onClick={() => {
              // if (onCurrencySelect) {
              //   setModalOpen(true)
              // }
            }}
          >
            <Aligner>
              <RowFixed>
                {isPlayDappNetwork && (
                  <>
                    <span style={{ marginRight: '0.5rem' }}>
                      <CurrencyLogo style={{ marginRight: '2px' }} currency={currency} size="24px" />
                    </span>
                    <StyledTokenName className="pair-name-container">PDA</StyledTokenName>
                  </>
                )}
                {!isPlayDappNetwork && (
                  <>
                    {pair ? (
                      <span style={{ marginRight: '0.5rem' }}>
                        <DoubleCurrencyLogo currency0={pair.token0} currency1={pair.token1} size={24} margin={true} />
                      </span>
                    ) : currency ? (
                      <CurrencyLogo style={{ marginRight: '2px' }} currency={currency} size="24px" />
                    ) : null}
                    {pair ? (
                      <StyledTokenName className="pair-name-container">
                        {pair?.token0.symbol}:{pair?.token1.symbol}
                      </StyledTokenName>
                    ) : (
                      <StyledTokenName className="token-symbol-container" active={Boolean(currency && currency.symbol)}>
                        {(currency && currency.symbol && currency.symbol.length > 20
                          ? currency.symbol.slice(0, 4) +
                            '...' +
                            currency.symbol.slice(currency.symbol.length - 5, currency.symbol.length)
                          : currency?.symbol) || <Trans>Select token</Trans>}
                      </StyledTokenName>
                    )}
                  </>
                )}
              </RowFixed>
              {/* {onCurrencySelect && <StyledDropDown selected={!!currency} />} */}
            </Aligner>
          </CurrencySelect>
        </InputRow>
        {Boolean(!hideInput && !hideBalance) && (
          <FiatRow>
            <RowBetween>
              {fee && fee.gt(0) && (
                <RowFixed style={{ height: '17px' }}>
                  <ThemedText.DeprecatedBody
                    data-testid="balance-text"
                    color={theme.textSecondary}
                    fontWeight={400}
                    fontSize={14}
                    style={{ display: 'inline' }}
                  >
                    Fee: {fee ? parseFloat(ethers.utils.formatUnits(fee, 18)) : 0} PDA
                  </ThemedText.DeprecatedBody>
                </RowFixed>
              )}
              <LoadingOpacityContainer $loading={loading}>
                {/* {fiatValue && <FiatValue fiatValue={fiatValue} priceImpact={priceImpact} />} */}
              </LoadingOpacityContainer>

              {/*{isPlayDappNetwork && (*/}
              {/*  <RowFixed style={{ height: '17px' }}>*/}
              {/*    <ThemedText.DeprecatedBody*/}
              {/*      data-testid="balance-text"*/}
              {/*      color={theme.textSecondary}*/}
              {/*      fontWeight={400}*/}
              {/*      fontSize={14}*/}
              {/*      style={{ display: 'inline' }}*/}
              {/*    >*/}
              {/*      {nativeBalance !== '-1' && <>Balance: {nativeBalance}</>}*/}
              {/*    </ThemedText.DeprecatedBody>*/}
              {/*    {showMaxButton && selectedCurrencyBalance ? (*/}
              {/*      <TraceEvent*/}
              {/*        events={[BrowserEvent.onClick]}*/}
              {/*        name={SwapEventName.SWAP_MAX_TOKEN_AMOUNT_SELECTED}*/}
              {/*        element={InterfaceElementName.MAX_TOKEN_AMOUNT_BUTTON}*/}
              {/*      >*/}
              {/*        <StyledBalanceMax onClick={onMax}>*/}
              {/*          <Trans>Max</Trans>*/}
              {/*        </StyledBalanceMax>*/}
              {/*      </TraceEvent>*/}
              {/*    ) : null}*/}
              {/*  </RowFixed>*/}
              {/*)}*/}
              {/*{!isPlayDappNetwork && (*/}
              <>
                {account ? (
                  <RowFixed style={{ height: '17px' }}>
                    <ThemedText.DeprecatedBody
                      data-testid="balance-text"
                      color={theme.textSecondary}
                      fontWeight={400}
                      fontSize={14}
                      style={{ display: 'inline' }}
                    >
                      {!hideBalance && balance ? (
                        // <>Balance: {formatCurrencyAmount(selectedCurrencyBalance, NumberType.TokenNonTx)}</>
                        <div style={{ display: 'flex', alignItems: 'center', gap: '4px' }}>
                          {parseFloat(ethers.utils.formatUnits(balance, 18).toString()).toFixed(3)}{' '}
                          <svg
                            xmlns="http://www.w3.org/2000/svg"
                            width="12"
                            height="12"
                            viewBox="0 0 12 12"
                            fill="none"
                          >
                            <path
                              fillRule="evenodd"
                              clipRule="evenodd"
                              d="M1.82179 2.1C1.51784 2.1 1.35 2.31474 1.35 2.48709C1.35 2.65944 1.51784 2.87418 1.82179 2.87418H8.97968V2.29628C8.97968 2.22931 8.90554 2.1 8.71717 2.1H1.82179ZM9.95467 2.87417V2.29628C9.95467 1.60797 9.35724 1.125 8.71717 1.125H1.82179C1.06614 1.125 0.375 1.6934 0.375 2.48709V9.91911C0.375 10.4885 0.866663 10.875 1.37625 10.875H10.11C10.6196 10.875 11.1112 10.4885 11.1112 9.91911V8.53305H11.1375C11.4067 8.53305 11.625 8.31479 11.625 8.04556V5.46942C11.625 5.20018 11.4067 4.98192 11.1375 4.98192H11.1112V3.83006C11.1112 3.2607 10.6196 2.87417 10.11 2.87417L9.95467 2.87417ZM10.1362 4.98192V3.85401C10.1305 3.85153 10.1218 3.84917 10.11 3.84917L9.46718 3.84918L1.82179 3.84918C1.65948 3.84918 1.50015 3.82296 1.35 3.77391V9.89515C1.35578 9.89764 1.36443 9.9 1.37625 9.9H10.11C10.1218 9.9 10.1305 9.89764 10.1362 9.89515V8.53305H9.46781C8.46169 8.53305 7.5675 7.77954 7.5675 6.75749C7.5675 5.73544 8.46169 4.98192 9.46781 4.98192H10.1362ZM9.46781 7.55805C8.91339 7.55805 8.5425 7.1582 8.5425 6.75749C8.5425 6.35678 8.91339 5.95692 9.46781 5.95692H10.65V7.55805H9.46781Z"
                              fill="#646464"
                            />
                          </svg>
                        </div>
                      ) : null}
                    </ThemedText.DeprecatedBody>
                    {showMaxButton && selectedCurrencyBalance ? (
                      <StyledBalanceMax onClick={onMax}>
                        <Trans>Max</Trans>
                      </StyledBalanceMax>
                    ) : null}
                  </RowFixed>
                ) : (
                  <span />
                )}
              </>
              {/*)}*/}
            </RowBetween>
          </FiatRow>
        )}
      </Container>
      {/* {onCurrencySelect && (
        <CurrencySearchModal
          isOpen={modalOpen}
          onDismiss={handleDismissSearch}
          onCurrencySelect={onCurrencySelect}
          selectedCurrency={currency}
          otherSelectedCurrency={otherCurrency}
          showCommonBases={showCommonBases}
          showCurrencyAmount={showCurrencyAmount}
          disableNonToken={disableNonToken}
        />
      )} */}
    </InputPanel>
  )
}
