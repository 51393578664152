import { Percent } from '@uniswap/sdk-core'
import { useFiatOnRampButtonEnabled } from 'featureFlags/flags/fiatOnRampButton'
import styled from 'styled-components/macro'
import { ThemedText } from 'theme'

import { RowBetween, RowFixed } from '../Row'

const StyledSwapHeader = styled(RowBetween)`
  margin-bottom: 16px;
  color: ${({ theme }) => theme.textSecondary};
`

const HeaderButtonContainer = styled(RowFixed)`
  align-items: center;
  padding: 0 12px;
  gap: 0;
`

const ButtonInfo = styled.button`
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 4px;
  outline: none;
  border: none;
  background: transparent;
  cursor: pointer;

  &:hover {
    opacity: 0.8;
  }
`

const IconInfo = () => (
  <svg xmlns="http://www.w3.org/2000/svg" width="18" height="18" viewBox="0 0 18 18" fill="none">
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M1.125 9C1.125 4.65076 4.65076 1.125 9 1.125C13.3492 1.125 16.875 4.65076 16.875 9C16.875 13.3492 13.3492 16.875 9 16.875C4.65076 16.875 1.125 13.3492 1.125 9ZM9.73136 6.32109C9.73136 5.91724 9.40397 5.58984 9.00011 5.58984C8.59625 5.58984 8.26886 5.91724 8.26886 6.32109V6.35249C8.26886 6.75635 8.59625 7.08374 9.00011 7.08374C9.40397 7.08374 9.73136 6.75635 9.73136 6.35249V6.32109ZM9.73136 9C9.73136 8.59614 9.40397 8.26875 9.00011 8.26875C8.59625 8.26875 8.26886 8.59614 8.26886 9L8.26886 12.5719C8.26886 12.9757 8.59625 13.3031 9.00011 13.3031C9.40397 13.3031 9.73136 12.9757 9.73136 12.5719L9.73136 9Z"
      fill="#AFAFAF"
    />
  </svg>
)

export default function SwapHeader({ autoSlippage, chainId }: { autoSlippage: Percent; chainId?: number }) {
  const fiatOnRampButtonEnabled = useFiatOnRampButtonEnabled()

  return (
    <StyledSwapHeader>
      <HeaderButtonContainer>
        <ThemedText.SubHeaderLarge fontWeight={600}>Bridge</ThemedText.SubHeaderLarge>
        {/*<ButtonInfo>*/}
        {/*  <IconInfo />*/}
        {/*</ButtonInfo>*/}
        {/*{fiatOnRampButtonEnabled && <SwapBuyFiatButton />}*/}
      </HeaderButtonContainer>
      <RowFixed>{/* <SettingsTab autoSlippage={autoSlippage} chainId={chainId} /> */}</RowFixed>
    </StyledSwapHeader>
  )
}
