import Column from 'components/Column'
import EthereumNetwork from 'components/Icons/EthereumNetwork'
import PlayDappNetwork from 'components/Icons/PlayDappNetwork'
import Row from 'components/Row'
import { MouseoverTooltip } from 'components/Tooltip'
import { ethers } from 'ethers'
import { useWindowSize } from 'hooks/useWindowSize'
import React, { createElement, PropsWithChildren, ReactNode } from 'react'
import { TextProps } from 'rebass'
import { Field } from 'state/swap/actions'
import styled from 'styled-components/macro'
import { ThemedText } from 'theme'

const MAX_AMOUNT_STR_LENGTH = 9

export const Label = styled(ThemedText.BodyPrimary)<{ cursor?: string }>`
  cursor: ${({ cursor }) => cursor};
  color: ${({ theme }) => theme.black};
  font-size: 14px !important;
  font-weight: 400 !important;
  margin-right: 8px;
`

const ResponsiveHeadline = ({ children, ...textProps }: PropsWithChildren<TextProps>) => {
  const { width } = useWindowSize()

  // if (width && width < BREAKPOINTS.xs) {
  //   return <ThemedText.HeadlineMedium {...textProps}>{children}</ThemedText.HeadlineMedium>
  // }
  //
  // return (
  //   <ThemedText.HeadlineLarge fontWeight={500} {...textProps}>
  //     {children}
  //   </ThemedText.HeadlineLarge>
  // )

  return <ThemedText.HeadlineSmall {...textProps}>{children}</ThemedText.HeadlineSmall>
}

const AmountWrapper = styled.div`
  display: flex;
  align-items: flex-end;
  gap: 24px;

  img {
    flex: none;
    width: 40px;
    height: 40px;
  }
`

const AmountBox = styled.div`
  overflow-x: auto;
  display: flex;
  flex-direction: column;
  gap: 4px;
  flex: 1;
  width: 100%;
  border-radius: 10px;
`

const AmountTitleBox = styled.div`
  display: flex;
  align-items: center;
  gap: 8px;
  font-size: 14px;
`

interface AmountProps {
  field: Field
  tooltipText?: ReactNode
  label: ReactNode
  amount?: number
  usdAmount?: number
  network: string
  fee?: ethers.BigNumber
}

const networkToIcon = (name: string) => {
  switch (name) {
    case 'Ethereum':
      return createElement(EthereumNetwork)
    case 'Sepolia':
      return createElement(EthereumNetwork)
    case 'PlayDapp':
      return createElement(PlayDappNetwork)
    default:
      return createElement(PlayDappNetwork)
  }
}

export function SwapModalHeaderAmount({ tooltipText, label, amount, usdAmount, field, network, fee }: AmountProps) {
  // let formattedAmount = formatCurrencyAmount(amount, NumberType.TokenTx)
  // if (formattedAmount.length > MAX_AMOUNT_STR_LENGTH) {
  //   formattedAmount = formatCurrencyAmount(amount, NumberType.SwapTradeAmount)
  // }

  return (
    <Row align="center" justify="space-between" gap="md">
      <Column
        gap="sm"
        style={{ width: '100%', borderRadius: '12px', border: '1px solid #ebebeb', padding: '16px 12px' }}
      >
        <ThemedText.BodySecondary>
          <MouseoverTooltip text={tooltipText} disabled={!tooltipText}>
            <Label cursor="help">{label}</Label>
          </MouseoverTooltip>
        </ThemedText.BodySecondary>
        <Column gap="xs">
          <AmountWrapper>
            <AmountBox>
              <AmountTitleBox>
                <span>{networkToIcon(network)}</span>
                <span>PDA on {network}</span>
              </AmountTitleBox>
              <ResponsiveHeadline data-testid={`${field}-amount`}>
                {/*{formattedAmount} {amount?.currency.symbol}*/}
                {amount} PDA
              </ResponsiveHeadline>

              {/*{usdAmount && (*/}
              {/*  <ThemedText.BodySmall color="textTertiary">*/}
              {/*    {formatNumber(usdAmount, NumberType.FiatTokenQuantity)}*/}
              {/*  </ThemedText.BodySmall>*/}
              {/*)}*/}
            </AmountBox>
            <img src="https://images.playdapp.com/brand-pda.svg" alt="pda-symbol" />
          </AmountWrapper>
        </Column>
      </Column>
      {/*{amount?.currency && <CurrencyLogo currency={amount.currency} size="36px" />}*/}
    </Row>
  )
}
