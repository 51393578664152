import gql from 'graphql-tag'

export const DEPOSIT_ITEMS = gql`
  query DepositItem($id: String!) {
    depositItems(where: { depositor: $id }, orderBy: blockNumber, orderDirection: desc) {
      id
      resourceID
      depositor
      depositNonce
      blockNumber
      transactionHash
      destinationChainID
    }
  }
`

export const PROPOSAL_EVENTS = gql`
  query ProposalEvents {
    proposalEvents(orderBy: blockNumber, orderDirection: desc) {
      id
      resourceID
      depositNonce
      blockNumber
      status
      transactionHash
      originChainID
    }
  }
`
