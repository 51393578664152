import * as React from 'react'
import { SVGProps } from 'react'

const SVGComponent = (props: SVGProps<SVGSVGElement> & { size?: number }) => (
  <svg
    width={props.size ?? 16}
    height={props.size ?? 16}
    viewBox="0 0 16 16"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M16 8C16 12.418 12.418 16 8 16C3.582 16 0 12.418 0 8C0 3.582 3.582 0 8 0C12.418 0 16 3.582 16 8Z"
      fill="#12CEB0"
      fillOpacity={0.15}
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M16 8C16 12.418 12.418 16 8 16C3.582 16 0 12.418 0 8C0 3.582 3.582 0 8 0C12.418 0 16 3.582 16 8Z"
      fill="#04D6B4"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M4.94061 5.36951C4.81613 5.29358 4.70301 5.20044 4.60461 5.09284V11.6488C4.60461 12.2755 5.12595 12.7842 5.76861 12.7842C6.41128 12.7842 6.93195 12.2762 6.93195 11.6488V6.58417L4.94061 5.36951ZM12.9599 7.26151C12.7921 7.00483 12.531 6.82357 12.2318 6.75612C11.9327 6.68867 11.619 6.74034 11.3573 6.90017L7.32861 9.35751V11.6488C7.32861 11.7962 7.30661 11.9388 7.26661 12.0742L12.5906 8.82617C12.7194 8.74934 12.8314 8.64743 12.92 8.52649C13.0087 8.40555 13.0722 8.26804 13.1067 8.12212C13.1412 7.9762 13.146 7.82483 13.1209 7.677C13.0958 7.52917 13.0413 7.38788 12.9606 7.26151H12.9599ZM5.15195 5.04084L9.40395 7.63417L11.1466 6.57151C11.3199 6.46617 11.5106 6.39617 11.7093 6.36284L6.38528 3.11484C6.12358 2.95474 5.80982 2.90285 5.51051 2.97018C5.2112 3.03751 4.94988 3.21876 4.78195 3.47551C4.70113 3.6019 4.64652 3.74325 4.62138 3.89115C4.59623 4.03905 4.60106 4.1905 4.63557 4.3365C4.67008 4.4825 4.73357 4.62008 4.82228 4.74108C4.91098 4.86207 5.02309 4.96401 5.15195 5.04084Z"
      fill="white"
    />
  </svg>
)
export default SVGComponent
