import { Trace, TraceEvent, useTrace } from '@uniswap/analytics'
import {
  BrowserEvent,
  InterfaceElementName,
  InterfaceEventName,
  InterfacePageName,
  InterfaceSectionName,
  SharedEventName,
  SwapEventName,
} from '@uniswap/analytics-events'
import { formatCurrencyAmount, NumberType } from '@uniswap/conedison/format'
import { Currency, CurrencyAmount, Percent, Token } from '@uniswap/sdk-core'
import { useWeb3React } from '@web3-react/core'
import { useToggleAccountDrawer } from 'components/AccountDrawer'
import { parseLocalActivity } from 'components/AccountDrawer/MiniPortfolio/Activity/parseLocal'
import { sendEvent } from 'components/analytics'
import EthereumNetwork from 'components/Icons/EthereumNetwork'
import PlayDappNetwork from 'components/Icons/PlayDappNetwork'
import { NetworkAlert } from 'components/NetworkAlert/NetworkAlert'
import PriceImpactModal from 'components/swap/PriceImpactModal'
import PriceImpactWarning from 'components/swap/PriceImpactWarning'
import { getChainInfo } from 'constants/chainInfo'
import {
  BridgeChainIds,
  chainPair,
  destinationChainId,
  isSupportedChain,
  L1_CHAIN_IDS,
  SupportedChainId,
  SupportedL1ChainId,
} from 'constants/chains'
import { ethers } from 'ethers'
import useBridgeEventQuery from 'hooks/useBridgeEventQuery'
import { useDepositCallback } from 'hooks/useDepositCallback'
import useENSAddress from 'hooks/useENSAddress'
import { useMaxAmountIn } from 'hooks/useMaxAmountIn'
import usePermit2Allowance, { Allowance, AllowanceState } from 'hooks/usePermit2Allowance'
import usePrevious from 'hooks/usePrevious'
import { useSwitchChain } from 'hooks/useSwitchChain'
import { useUSDPrice } from 'hooks/useUSDPrice'
import JSBI from 'jsbi'
import { chainIdToNetworkName } from 'lib/hooks/useCurrencyLogoURIs'
import { createElement, ReactNode, useCallback, useEffect, useMemo, useReducer, useState } from 'react'
import { ArrowDown, ExternalLink as ExternalLinkIconFeather } from 'react-feather'
import { useLocation, useNavigate } from 'react-router-dom'
import { Text } from 'rebass'
import { useActivePopups } from 'state/application/hooks'
import { useAppSelector } from 'state/hooks'
import { InterfaceTrade, TradeState } from 'state/routing/types'
import { useAllTransactions, useTransaction } from 'state/transactions/hooks'
import styled, { keyframes, useTheme } from 'styled-components/macro'
import { didUserReject } from 'utils/swapErrorToUserReadableMessage'

import bridgeABI from '../../bridge/build/contracts/Bridge.json'
import { getActivityTitle } from '../../components/AccountDrawer/MiniPortfolio/constants'
import AddressInputPanel from '../../components/AddressInputPanel'
import { ButtonError, ButtonPrimary } from '../../components/Button'
import { AutoColumn } from '../../components/Column'
import SwapCurrencyInputPanel from '../../components/CurrencyInputPanel/SwapCurrencyInputPanel'
import { AutoRow } from '../../components/Row'
import confirmPriceImpactWithoutFee from '../../components/swap/confirmPriceImpactWithoutFee'
import ConfirmSwapModal from '../../components/swap/ConfirmSwapModal'
import { ArrowWrapper, PageWrapper, SwapWrapper } from '../../components/swap/styleds'
import SwapHeader from '../../components/swap/SwapHeader'
import { SwitchLocaleLink } from '../../components/SwitchLocaleLink'
import { BRIDGE_ADDRESSES, BRIDGE_GENERIC_HANDLER_ADDRESSES, BRIDGE_RELAYER_ADDRESSES } from '../../constants/addresses'
import { RPC_URLS } from '../../constants/networks'
import { getSwapCurrencyId, TOKEN_SHORTHANDS } from '../../constants/tokens'
import { TransactionStatus } from '../../graphql/data/__generated__/types-and-hooks'
import { useAllTokensMultichain, useCurrency, useDefaultActiveTokens } from '../../hooks/Tokens'
import { useBridgeContract, useBridgeGenericHandlerContract } from '../../hooks/useContract'
import { PermitInfo, PermitType } from '../../hooks/useERC20Permit'
import { useIsSwapUnsupported } from '../../hooks/useIsSwapUnsupported'
import useSelectChain from '../../hooks/useSelectChain'
import { useSwapCallback } from '../../hooks/useSwapCallback'
import useTransactionDeadline from '../../hooks/useTransactionDeadline'
import useWrapCallback, { WrapErrorText, WrapType } from '../../hooks/useWrapCallback'
import { useCurrencyBalance } from '../../state/connection/hooks'
import { Field, replaceSwapState } from '../../state/swap/actions'
import { useDefaultsFromURLSearch, useDerivedSwapInfo, useSwapActionHandlers } from '../../state/swap/hooks'
import swapReducer, { initialState as initialSwapState, SwapState } from '../../state/swap/reducer'
import { TransactionType } from '../../state/transactions/types'
import { LinkStyledButton, ThemedText } from '../../theme'
import { computeFiatValuePriceImpact } from '../../utils/computeFiatValuePriceImpact'
import { ExplorerDataType, getExplorerLink } from '../../utils/getExplorerLink'
import { maxAmountSpend } from '../../utils/maxAmountSpend'
import { computeRealizedPriceImpact, warningSeverity } from '../../utils/prices'
import { supportedChainId } from '../../utils/supportedChainId'

type BridgeProgressStatus = {
  nonce: number
  steps: { status: number; transactionHash: string }[]
  depositItem: {
    destinationChainID: number
    transactionHash: string
  }
  proposalItemPending: { transactionHash: string }[]
  proposalItemComplete: { transactionHash: string }[]
}

export const ArrowContainer = styled.div`
  display: inline-flex;
  align-items: center;
  justify-content: center;

  width: 100%;
  height: 100%;
`

const SwapSection = styled.div`
  position: relative;
  display: flex;
  flex-direction: column;
  gap: 8px;
  background-color: ${({ theme }) => theme.backgroundModule};
  border-radius: 12px;
  padding: 16px;
  color: ${({ theme }) => theme.textSecondary};
  font-size: 14px;
  line-height: 20px;
  font-weight: 500;

  &:before {
    box-sizing: border-box;
    background-size: 100%;
    border-radius: inherit;

    position: absolute;
    top: 0;
    left: 0;

    width: 100%;
    height: 100%;
    pointer-events: none;
    content: '';
    border: 1px solid ${({ theme }) => theme.backgroundModule};
  }

  &:hover:before {
    border-color: ${({ theme }) => theme.stateOverlayHover};
  }

  &:focus-within:before {
    border-color: ${({ theme }) => theme.stateOverlayPressed};
  }
`

const OutputSwapSection = styled(SwapSection)`
  border-bottom: ${({ theme }) => `1px solid ${theme.backgroundSurface}`};
`

const rotateKeyframe = keyframes`
    0% {
        transform: rotate(0deg);
    }
    100% {
        transform: rotate(360deg);
    }
`

const BridgeProgressBlock = styled.div`
  display: flex;
  flex-direction: column;
  padding: 16px;
`

const BridgeProgressHeading = styled.h4`
  margin-bottom: 8px;
  font-size: 12px;
  font-style: normal;
  font-weight: 600;
  line-height: 150%;
  color: #646464;
`

const BridgeStepBlock = styled.div`
  display: flex;
  align-items: flex-start;
  gap: 8px;
`

const BridgeStepperBlock = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
`

const BridgeStepNumberBlock = styled.div`
  display: flex;
  position: relative;

  span {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    font-size: 12px;
    font-style: normal;
    font-weight: 600;
    line-height: 150%; /* 18px */
    color: ${({ theme }) => theme.textSecondary};
  }
`

const BridgeStepNumber = styled.div<{ isActive?: boolean; isCompleted?: boolean }>`
  width: 20px;
  height: 20px;
  border: 2px solid ${({ isActive, isCompleted }) => (isActive || isCompleted ? '#00b6a1' : '#F0F0F0')};
  border-bottom-color: ${({ isActive, isCompleted }) => (isActive ? '#00b6a14c' : isCompleted ? '#00b6a1' : '#F0F0F0')};
  border-radius: 50%;
  display: inline-block;
  box-sizing: border-box;
  animation: ${rotateKeyframe} 2s linear infinite;
`

const BridgeStepperRuler = styled.div<{ isActive?: boolean; isCompleted?: boolean }>`
  width: 3px;
  min-height: ${({ isActive }) => (isActive ? '72px' : '16px')};
  height: auto;
  background-color: ${({ isActive, isCompleted }) => (isActive || isCompleted ? '#00b6a1' : '#F0F0F0')};
`

const BridgeStepTextBlock = styled.div<{ isActive?: boolean; isCompleted?: boolean }>`
  display: flex;
  flex-direction: column;
  gap: 6px;
  margin-top: -2px;

  h4 {
    display: flex;
    align-items: flex-end;
    gap: 4px;
    margin: 0;
    font-size: 16px;
    font-weight: 600;
    line-height: 150%;
    color: ${({ isActive, isCompleted, theme }) => (isActive || isCompleted ? '#00b6a1' : theme.textPrimary)};
  }

  p,
  a {
    margin: 0;
    font-family: Inter, sans-serif;
    font-size: 14px;
    font-style: normal;
    font-weight: 500;
    line-height: 20px;
    color: #565c63;
  }

  a:hover {
    text-decoration: underline;
  }
`

const MainContentWrapper = styled.main`
  background-color: ${({ theme }) => theme.backgroundSurface};
  border: 1px solid ${({ theme }) => theme.backgroundOutline};
  padding: 0;
  border-radius: 16px;
  display: flex;
  flex-direction: column;
  box-shadow: 0px 0px 1px rgba(0, 0, 0, 0.01), 0px 4px 8px rgba(0, 0, 0, 0.04), 0px 16px 24px rgba(0, 0, 0, 0.04),
    0px 24px 32px rgba(0, 0, 0, 0.01);
  overflow: hidden;
  margin-bottom: 50px;
`

const ErrorContainer = styled.div`
  align-items: center;
  display: flex;
  flex-direction: column;
  justify-content: center;
  margin: auto;
  max-width: 300px;
  min-height: 80px;
`

function getIsValidSwapQuote(
  trade: InterfaceTrade | undefined,
  tradeState: TradeState,
  swapInputError?: ReactNode
): boolean {
  return Boolean(swapInputError && trade && tradeState === TradeState.VALID)
}

function largerPercentValue(a?: Percent, b?: Percent) {
  if (a && b) {
    return a.greaterThan(b) ? a : b
  } else if (a) {
    return a
  } else if (b) {
    return b
  }
  return undefined
}

const TRADE_STRING = 'SwapRouter'

export default function BridgePage({ className }: { className?: string }) {
  const { chainId: connectedChainId } = useWeb3React()
  const loadedUrlParams = useDefaultsFromURLSearch()

  const location = useLocation()

  return (
    <Trace page={InterfacePageName.SWAP_PAGE} shouldLogImpression>
      <PageWrapper>
        <Bridge
          className={className}
          chainId={connectedChainId}
          prefilledState={{
            [Field.INPUT]: { currencyId: loadedUrlParams?.[Field.INPUT]?.currencyId },
            [Field.OUTPUT]: { currencyId: loadedUrlParams?.[Field.OUTPUT]?.currencyId },
          }}
        />
        <NetworkAlert />
      </PageWrapper>
      {location.pathname === '/swap' && <SwitchLocaleLink />}
    </Trace>
  )
}

/**
 * The swap component displays the swap interface, manages state for the swap, and triggers onchain swaps.
 *
 * In most cases, chainId should refer to the connected chain, i.e. `useWeb3React().chainId`.
 * However if this component is being used in a context that displays information from a different, unconnected
 * chain (e.g. the TDP), then chainId should refer to the unconnected chain.
 */

const initialState = {
  showConfirm: false,
  tradeToConfirm: undefined,
  swapError: undefined,
  txHash: undefined,
}

const networkToIcon = (chainId: number) => {
  switch (chainId) {
    case SupportedChainId.MAINNET:
      return createElement(EthereumNetwork)
    case SupportedChainId.SEPOLIA:
      return createElement(EthereumNetwork)
    case SupportedChainId.PLAYDAPP_TESTNET:
      return createElement(PlayDappNetwork)
    case SupportedChainId.PLAYDAPP_MAINNET:
      return createElement(PlayDappNetwork)
    default:
      return createElement(PlayDappNetwork)
  }
}

// eslint-disable-next-line import/no-unused-modules
export function Bridge({
  className,
  prefilledState = {},
  chainId,
  onCurrencyChange,
  disableTokenInputs = false,
}: {
  className?: string
  prefilledState?: Partial<SwapState>
  chainId?: SupportedChainId
  onCurrencyChange?: (selected: Pick<SwapState, Field.INPUT | Field.OUTPUT>) => void
  disableTokenInputs?: boolean
}) {
  const { account, chainId: connectedChainId, connector, provider } = useWeb3React()
  const trace = useTrace()

  // token warning stuff
  const prefilledInputCurrency = useCurrency(prefilledState?.[Field.INPUT]?.currencyId)
  const prefilledOutputCurrency = useCurrency(prefilledState?.[Field.OUTPUT]?.currencyId)

  const [loadedInputCurrency, setLoadedInputCurrency] = useState(prefilledInputCurrency)
  const [loadedOutputCurrency, setLoadedOutputCurrency] = useState(prefilledOutputCurrency)

  useEffect(() => {
    setLoadedInputCurrency(prefilledInputCurrency)
    setLoadedOutputCurrency(prefilledOutputCurrency)
  }, [prefilledInputCurrency, prefilledOutputCurrency])

  const [dismissTokenWarning, setDismissTokenWarning] = useState<boolean>(false)
  const [showPriceImpactModal, setShowPriceImpactModal] = useState<boolean>(false)

  const urlLoadedTokens: Token[] = useMemo(
    () => [loadedInputCurrency, loadedOutputCurrency]?.filter((c): c is Token => c?.isToken ?? false) ?? [],
    [loadedInputCurrency, loadedOutputCurrency]
  )
  const handleConfirmTokenWarning = useCallback(() => {
    setDismissTokenWarning(true)
  }, [])

  // dismiss warning if all imported tokens are in active lists
  const defaultTokens = useDefaultActiveTokens(chainId)

  const importTokensNotInDefault = useMemo(
    () =>
      urlLoadedTokens &&
      urlLoadedTokens
        .filter((token: Token) => {
          return !(token.address in defaultTokens)
        })
        .filter((token: Token) => {
          // Any token addresses that are loaded from the shorthands map do not need to show the import URL
          const supported = supportedChainId(chainId)
          if (!supported) return true
          return !Object.keys(TOKEN_SHORTHANDS).some((shorthand) => {
            const shorthandTokenAddress = TOKEN_SHORTHANDS[shorthand][supported]
            return shorthandTokenAddress && shorthandTokenAddress === token.address
          })
        }),
    [chainId, defaultTokens, urlLoadedTokens]
  )

  const theme = useTheme()

  // toggle wallet when disconnected
  const toggleWalletDrawer = useToggleAccountDrawer()

  // swap state
  const [state, dispatch] = useReducer(swapReducer, { ...initialSwapState, ...prefilledState })
  const { typedValue, recipient, independentField } = state

  const previousConnectedChainId = usePrevious(connectedChainId)
  const previousPrefilledState = usePrevious(prefilledState)
  useEffect(() => {
    const combinedInitialState = { ...initialSwapState, ...prefilledState }
    const chainChanged = previousConnectedChainId && previousConnectedChainId !== connectedChainId
    const prefilledInputChanged =
      previousPrefilledState &&
      previousPrefilledState?.[Field.INPUT]?.currencyId !== prefilledState?.[Field.INPUT]?.currencyId
    const prefilledOutputChanged =
      previousPrefilledState &&
      previousPrefilledState?.[Field.OUTPUT]?.currencyId !== prefilledState?.[Field.OUTPUT]?.currencyId
    if (chainChanged || prefilledInputChanged || prefilledOutputChanged) {
      dispatch(
        replaceSwapState({
          ...initialSwapState,
          ...prefilledState,
          field: combinedInitialState.independentField ?? Field.INPUT,
          inputCurrencyId: combinedInitialState.INPUT.currencyId ?? undefined,
          outputCurrencyId: combinedInitialState.OUTPUT.currencyId ?? undefined,
        })
      )
      // reset local state
      setSwapState({
        tradeToConfirm: undefined,
        swapError: undefined,
        showConfirm: false,
        txHash: undefined,
      })
    }
  }, [connectedChainId, prefilledState, previousConnectedChainId, previousPrefilledState])

  const {
    trade: { state: tradeState, trade, method },
    allowedSlippage,
    autoSlippage,
    currencyBalances,
    parsedAmount,
    currencies,
    inputError: swapInputError,
  } = useDerivedSwapInfo(state, chainId)

  const {
    wrapType,
    execute: onWrap,
    inputError: wrapInputError,
  } = useWrapCallback(currencies[Field.INPUT], currencies[Field.OUTPUT], typedValue)
  const showWrap: boolean = wrapType !== WrapType.NOT_APPLICABLE
  const { address: recipientAddress } = useENSAddress(recipient)

  const parsedAmounts = useMemo(
    () =>
      showWrap
        ? {
            [Field.INPUT]: parsedAmount,
            [Field.OUTPUT]: parsedAmount,
          }
        : {
            [Field.INPUT]: independentField === Field.INPUT ? parsedAmount : trade?.inputAmount,
            [Field.OUTPUT]: independentField === Field.OUTPUT ? parsedAmount : trade?.outputAmount,
          },
    [independentField, parsedAmount, showWrap, trade]
  )

  const fiatValueInput = useUSDPrice(parsedAmounts[Field.INPUT])
  const fiatValueOutput = useUSDPrice(parsedAmounts[Field.OUTPUT])
  const showFiatValueInput = Boolean(parsedAmounts[Field.INPUT])
  const showFiatValueOutput = Boolean(parsedAmounts[Field.OUTPUT])

  const [routeNotFound, routeIsLoading, routeIsSyncing] = useMemo(
    () => [!trade?.swaps, TradeState.LOADING === tradeState, TradeState.LOADING === tradeState && Boolean(trade)],
    [trade, tradeState]
  )

  const fiatValueTradeInput = useUSDPrice(trade?.inputAmount)
  const fiatValueTradeOutput = useUSDPrice(trade?.outputAmount)
  const stablecoinPriceImpact = useMemo(
    () =>
      routeIsSyncing || !trade
        ? undefined
        : computeFiatValuePriceImpact(fiatValueTradeInput.data, fiatValueTradeOutput.data),
    [fiatValueTradeInput, fiatValueTradeOutput, routeIsSyncing, trade]
  )

  const { onSwitchTokens, onCurrencySelection, onUserInput, onChangeRecipient } = useSwapActionHandlers(dispatch)
  const isValid = !swapInputError
  const dependentField: Field = independentField === Field.INPUT ? Field.OUTPUT : Field.INPUT

  const handleTypeInput = useCallback(
    (value: string) => {
      onUserInput(Field.INPUT, value)
    },
    [onUserInput]
  )
  const handleTypeOutput = useCallback(
    (value: string) => {
      onUserInput(Field.OUTPUT, value)
    },
    [onUserInput]
  )

  const navigate = useNavigate()
  const swapIsUnsupported = useIsSwapUnsupported(currencies[Field.INPUT], currencies[Field.OUTPUT])

  // reset if they close warning without tokens in params
  const handleDismissTokenWarning = useCallback(() => {
    setDismissTokenWarning(true)
    navigate('/swap/')
  }, [navigate])

  // modal and loading
  const [{ showConfirm, tradeToConfirm, swapError, txHash }, setSwapState] = useState<{
    showConfirm: boolean
    tradeToConfirm?: InterfaceTrade
    swapError?: Error
    txHash?: string
  }>(initialState)

  const formattedAmounts = useMemo(
    () => ({
      [independentField]: typedValue,
      [dependentField]: showWrap
        ? parsedAmounts[independentField]?.toExact() ?? ''
        : formatCurrencyAmount(parsedAmounts[dependentField], NumberType.SwapTradeAmount, ''),
    }),
    [dependentField, independentField, parsedAmounts, showWrap, typedValue]
  )

  const userHasSpecifiedInputOutput = Boolean(
    currencies[Field.INPUT] && currencies[Field.OUTPUT] && parsedAmounts[independentField]?.greaterThan(JSBI.BigInt(0))
  )
  const bridgeContract = useBridgeContract()
  const bridgeGennericHandlerContract = useBridgeGenericHandlerContract()
  const maximumAmountIn = useMaxAmountIn(trade, allowedSlippage)
  const allowance: Allowance = usePermit2Allowance(
    parsedAmounts[Field.INPUT] as CurrencyAmount<Token>,
    bridgeGennericHandlerContract?.address
  )
  // console.log('allowance1234', allowance)
  // console.log('parsedAmounts', parsedAmounts, parsedAmounts[Field.INPUT])
  const maxInputAmount: CurrencyAmount<Currency> | undefined = useMemo(
    () => maxAmountSpend(currencyBalances[Field.INPUT]),
    [currencyBalances]
  )
  const showMaxButton = Boolean(maxInputAmount?.greaterThan(0) && !parsedAmounts[Field.INPUT]?.equalTo(maxInputAmount))
  const swapFiatValues = useMemo(() => {
    return { amountIn: fiatValueTradeInput.data, amountOut: fiatValueTradeOutput.data }
  }, [fiatValueTradeInput, fiatValueTradeOutput])

  // the callback to execute the swap
  const { callback: swapCallback } = useSwapCallback(
    trade,
    swapFiatValues,
    allowedSlippage,
    allowance.state === AllowanceState.ALLOWED ? allowance.permitSignature : undefined
  )

  const isPlayDappNetwork = useMemo(() => {
    return BridgeChainIds[chainId as SupportedL1ChainId] === 1
  }, [chainId])

  const { depositItemsData, proposalEventsData, bridgeProgressStep } = useBridgeEventQuery()

  const useBridgeFee = () => {
    const [fee, setFee] = useState<ethers.BigNumber>(ethers.BigNumber.from(0))
    const [error, setError] = useState<string | null>(null)

    useEffect(() => {
      const fetchFee = async () => {
        try {
          if (connectedChainId) {
            const destinationBridgeContract = new ethers.Contract(
              BRIDGE_ADDRESSES[connectedChainId as SupportedL1ChainId],
              bridgeABI.abi,
              provider
            )
            const fee = await destinationBridgeContract?._fee()
            console.log('fetch fee response', fee, connectedChainId)
            setFee(fee)
          }
        } catch (err) {
          setError(err.message)
        }
      }

      // Fetch the balance when the component mounts
      if (connectedChainId) {
        fetchFee()
      }
    }, [connectedChainId, bridgeProgressStep])

    return { fee, error }
  }

  //실제 수수료 제외하고 받을 금액
  const [destinationAmount, setDestinationAmount] = useState<ethers.BigNumber>(ethers.BigNumber.from(0))

  const { fee: bridgeFeeForDestinationChain, error: fetchFeeError } = useBridgeFee()
  // console.log('bridgeFee', bridgeFeeForDestinationChain.toString(), 'fetchFeeError', fetchFeeError)

  // console.log('currencies', currencies[Field.INPUT], currencies[Field.OUTPUT])
  const { callback: depositCallback } = useDepositCallback(
    allowance.state === AllowanceState.ALLOWED ? allowance.permitSignature : undefined,
    parsedAmounts[Field.INPUT],
    isPlayDappNetwork ? parsedAmounts[Field.INPUT]?.toExact() || '0' : undefined,
    destinationAmount?.toString() || '0'
  )
  const transactionDeadline = useTransactionDeadline()
  const PERMIT_INFO: PermitInfo = {
    version: '1',
    name: 'MMM COIN',
    type: PermitType.AMOUNT,
  }

  // console.log('bridgeContract', bridgeContract)
  // const { gatherPermitSignature, signatureData } = useERC20Permit(
  //   CurrencyAmount.fromRawAmount(PDA_PDTESTNET, 1e18),
  //   bridgeContract?.address,
  //   transactionDeadline,
  //   PERMIT_INFO
  // )

  const handleContinueToReview = useCallback(() => {
    // console.log('handleContinueToReview')
    setSwapState({
      tradeToConfirm: trade,
      swapError: undefined,
      showConfirm: true,
      txHash: undefined,
    })
  }, [trade])

  const handleSwap = useCallback(() => {
    console.log('handleSwap')
    if (!swapCallback) {
      return
    }
    if (stablecoinPriceImpact && !confirmPriceImpactWithoutFee(stablecoinPriceImpact)) {
      return
    }
    setSwapState((currentState) => ({
      ...currentState,
      swapError: undefined,
      txHash: undefined,
    }))
    swapCallback()
      .then((hash) => {
        setSwapState((currentState) => ({
          ...currentState,
          swapError: undefined,
          txHash: hash,
        }))
        sendEvent({
          category: 'Swap',
          action: 'transaction hash',
          label: hash,
        })
        sendEvent({
          category: 'Swap',
          action:
            recipient === null
              ? 'Swap w/o Send'
              : (recipientAddress ?? recipient) === account
              ? 'Swap w/o Send + recipient'
              : 'Swap w/ Send',
          label: [TRADE_STRING, trade?.inputAmount?.currency?.symbol, trade?.outputAmount?.currency?.symbol, 'MH'].join(
            '/'
          ),
        })
      })
      .catch((error) => {
        if (!didUserReject(error)) {
          // sendAnalyticsEvent(SwapEventName.SWAP_ERROR, {
          //   confirmedTrade: tradeToConfirm,
          // })
        }
        setSwapState((currentState) => ({
          ...currentState,
          swapError: error,
          txHash: undefined,
        }))
      })
  }, [
    swapCallback,
    stablecoinPriceImpact,
    recipient,
    recipientAddress,
    account,
    trade?.inputAmount?.currency?.symbol,
    trade?.outputAmount?.currency?.symbol,
    tradeToConfirm,
  ])

  const [hadleDepositRequested, setHandleDepositRequested] = useState(false)
  const handleDeposit = () => {
    console.log('handleDeposit')
    if (!depositCallback) {
      return
    }
    setHandleDepositRequested(true)
  }
  useEffect(() => {
    if (hadleDepositRequested) {
      performDeposit()
      setHandleDepositRequested(false)
    }
  }, [hadleDepositRequested])

  const performDeposit = useCallback(async () => {
    setSwapState((currentState) => ({
      ...currentState,
      swapError: undefined,
      txHash: undefined,
    }))
    if (!depositCallback) {
      return
    }
    depositCallback()
      .then((hash) => {
        setSwapState((currentState) => ({
          ...currentState,
          swapError: undefined,
          txHash: hash,
        }))
        // allowance.resetSignature && allowance.resetSignature()
      })
      .catch((error) => {
        if (!didUserReject(error)) {
          // sendAnalyticsEvent(SwapEventName.SWAP_ERROR, {
          //   confirmedTrade: tradeToConfirm,
          // })
        }
        setSwapState((currentState) => ({
          ...currentState,
          swapError: error,
          txHash: undefined,
        }))
        // allowance.resetSignature && allowance.resetSignature()
      })
  }, [depositCallback, account])

  // errors
  const [swapQuoteReceivedDate, setSwapQuoteReceivedDate] = useState<Date | undefined>()

  // warnings on the greater of fiat value price impact and execution price impact
  const { priceImpactSeverity, largerPriceImpact } = useMemo(() => {
    const marketPriceImpact = trade?.priceImpact ? computeRealizedPriceImpact(trade) : undefined
    const largerPriceImpact = largerPercentValue(marketPriceImpact, stablecoinPriceImpact)
    return { priceImpactSeverity: warningSeverity(largerPriceImpact), largerPriceImpact }
  }, [stablecoinPriceImpact, trade])

  const handleConfirmDismiss = useCallback(() => {
    setSwapState((currentState) => ({ ...currentState, showConfirm: false }))
    // if there was a tx hash, we want to clear the input
    if (txHash) {
      onUserInput(Field.INPUT, '')
    }
  }, [onUserInput, txHash])

  const handleAcceptChanges = useCallback(() => {
    setSwapState((currentState) => ({ ...currentState, tradeToConfirm: trade }))
  }, [trade])

  const handleInputSelect = useCallback(
    (inputCurrency: Currency) => {
      onCurrencySelection(Field.INPUT, inputCurrency)
      onCurrencyChange?.({
        [Field.INPUT]: {
          currencyId: getSwapCurrencyId(inputCurrency),
        },
        [Field.OUTPUT]: state[Field.OUTPUT],
      })
    },
    [onCurrencyChange, onCurrencySelection, state]
  )

  const handleMaxInput = useCallback(() => {
    maxInputAmount && onUserInput(Field.INPUT, maxInputAmount.toExact())
    sendEvent({
      category: 'Swap',
      action: 'Max',
    })
  }, [maxInputAmount, onUserInput])

  const handleOutputSelect = useCallback(
    (outputCurrency: Currency) => {
      // console.log('selected mbp network token', outputCurrency)
      // TODO: 코인 선택했을 때 동작 추가
      onCurrencySelection(Field.OUTPUT, outputCurrency)
      onCurrencyChange?.({
        [Field.INPUT]: state[Field.INPUT],
        [Field.OUTPUT]: {
          currencyId: getSwapCurrencyId(outputCurrency),
        },
      })
    },
    [onCurrencyChange, onCurrencySelection, state]
  )

  const showPriceImpactWarning = largerPriceImpact && priceImpactSeverity > 3

  const prevTrade = usePrevious(trade)
  useEffect(() => {
    if (!trade || prevTrade === trade) return // no new swap quote to log

    setSwapQuoteReceivedDate(new Date())
  }, [prevTrade, trade, trace, allowedSlippage, method])

  const showDetailsDropdown = Boolean(
    !showWrap && userHasSpecifiedInputOutput && (trade || routeIsLoading || routeIsSyncing)
  )

  const switchChain = useSwitchChain()
  const switchingChain = useAppSelector((state) => state.wallets.switchingChain)

  const targetChain: SupportedL1ChainId =
    connectedChainId && L1_CHAIN_IDS.some((id) => id === connectedChainId)
      ? chainPair[connectedChainId as SupportedL1ChainId]
      : SupportedChainId.MAINNET

  const destinatinChainId = BridgeChainIds[targetChain] ?? 0

  const activePopups = useActivePopups()
  const activeTxHash = useMemo(
    () => (activePopups.length > 0 && 'txn' in activePopups[0].content ? activePopups[0].content.txn.hash : undefined),
    [activePopups]
  )
  const tx = useTransaction(activeTxHash)
  const success = tx?.receipt?.status === 1
  const tokens = useAllTokensMultichain()
  const activity = tx && chainId ? parseLocalActivity(tx, chainId, tokens) : null

  const [isShowBridgeProgress, setIsShowBridgeProgress] = useState(false)

  useEffect(() => {
    if (
      depositItemsData &&
      Array.isArray(depositItemsData?.depositItems) &&
      depositItemsData?.depositItems.length > 0 &&
      activity &&
      activity.title === getActivityTitle(TransactionType.BRIDGE, TransactionStatus.Confirmed) &&
      success &&
      account &&
      connectedChainId
    ) {
      localStorage.setItem(`MPB_BRIDGE_LATELY_NONCE_${account}`, depositItemsData.depositItems[0].depositNonce)
      localStorage.setItem(`MPB_BRIDGE_LATELY_CHAIN_ID_${account}`, connectedChainId.toString())
      setIsShowBridgeProgress(true)
    }
  }, [depositItemsData, activity, success, account, connectedChainId])

  useEffect(() => {
    if (
      bridgeProgressStep &&
      bridgeProgressStep.length > 0 &&
      bridgeProgressStep[0].steps.length === 3 &&
      account &&
      bridgeProgressStep[0].nonce.toString() === localStorage.getItem(`MPB_BRIDGE_LATELY_NONCE_${account}`)
    ) {
      localStorage.removeItem(`MPB_BRIDGE_LATELY_NONCE_${account}`)
      localStorage.removeItem(`MPB_BRIDGE_LATELY_CHAIN_ID_${account}`)
    }
  }, [account, bridgeProgressStep])

  useEffect(() => {
    if (account && localStorage.getItem(`MPB_BRIDGE_LATELY_NONCE_${account}`)) {
      setIsShowBridgeProgress(true)
    }
  }, [account])

  const useDestinationChainBridgeEthBalance = () => {
    const [balance, setBalance] = useState<ethers.BigNumber>(ethers.BigNumber.from(0))
    const [handlerBalance, setHandlerBalance] = useState<ethers.BigNumber>(ethers.BigNumber.from(0))
    const [error, setError] = useState<string | null>(null)

    useEffect(() => {
      const fetchBalance = async () => {
        try {
          const chainId = BridgeChainIds[connectedChainId as SupportedL1ChainId]

          if (chainId === 0) {
            const provider = new ethers.providers.JsonRpcProvider(
              RPC_URLS[destinationChainId[connectedChainId as SupportedL1ChainId]][0]
            )
            const balance: ethers.BigNumber = await provider.getBalance(
              BRIDGE_ADDRESSES[destinationChainId[connectedChainId as SupportedL1ChainId]]
            )

            setBalance(balance)
          } else if (chainId === 1) {
            const pdaToken = tokens[chainPair[connectedChainId as SupportedL1ChainId]]

            const provider = new ethers.providers.JsonRpcProvider(
              RPC_URLS[chainPair[connectedChainId as SupportedL1ChainId]][0]
            )
            const erc20Contract = new ethers.Contract(
              Object.keys(pdaToken as any)[0] ?? '',
              ['function balanceOf(address) view returns (uint256)'],
              provider
            )
            const balanceErc20 =
              (await erc20Contract?.balanceOf(
                BRIDGE_GENERIC_HANDLER_ADDRESSES[chainPair[connectedChainId as SupportedL1ChainId]]
              )) ?? ethers.BigNumber.from(0)
            setHandlerBalance(balanceErc20)
          }
        } catch (err) {
          setError(err.message)
        }
      }

      // Fetch the balance when the component mounts
      if (connectedChainId) {
        fetchBalance()
      }
    }, [connectedChainId, bridgeProgressStep])

    return { balance, handlerBalance, error }
  }

  const {
    balance: destinationChainBridgeEthBalance,
    handlerBalance: destinationErc20PdaBalance,
    error: destinationChainBridgeEthBalanceError,
  } = useDestinationChainBridgeEthBalance()

  // console.log(
  //   '*balance_check',
  //   'destinationChainBridgeEthBalance',
  //   destinationChainBridgeEthBalance?.toString(),
  //   'destinationErc20PdaBalance',
  //   destinationErc20PdaBalance?.toString(),
  //   'destinationChainBridgeEthBalanceError',
  //   !!destinationChainBridgeEthBalanceError
  // )
  const useBridgeChainRelayerEthBalance = () => {
    const [ethBalanceSource, setEthBalanceSource] = useState<ethers.BigNumber | null>(null)
    const [ethBalanceDest, setEthBalanceDest] = useState<ethers.BigNumber | null>(null)
    const [destinationChainAccountBalance, setDestinationChainAccountBalance] = useState<ethers.BigNumber>(
      ethers.BigNumber.from(0)
    )
    const [error, setError] = useState<string | null>(null)
    const [loaded, setLoaded] = useState(false)
    useEffect(() => {
      const fetchBalance = async () => {
        try {
          const providerSource = new ethers.providers.JsonRpcProvider(
            RPC_URLS[connectedChainId as SupportedL1ChainId][0]
          )
          const providerDest = new ethers.providers.JsonRpcProvider(
            RPC_URLS[chainPair[connectedChainId as SupportedL1ChainId]][0]
          )
          const balanceSourceRelayer: ethers.BigNumber = await providerSource.getBalance(
            BRIDGE_RELAYER_ADDRESSES[connectedChainId as SupportedL1ChainId]
          )
          const balanceDestRelayer: ethers.BigNumber = await providerDest.getBalance(
            BRIDGE_RELAYER_ADDRESSES[chainPair[connectedChainId as SupportedL1ChainId]]
          )

          if (account) {
            if (destinatinChainId === 0) {
              const pdaToken = tokens[chainPair[connectedChainId as SupportedL1ChainId]]
              const erc20Contract = new ethers.Contract(
                Object.keys(pdaToken as any)[0] ?? '',
                ['function balanceOf(address) view returns (uint256)'],
                providerDest
              )
              const balance = (await erc20Contract?.balanceOf(account)) ?? ethers.BigNumber.from(0)
              setDestinationChainAccountBalance(balance)
            } else if (destinatinChainId === 1) {
              const destinationChainAccountNativeBalance: ethers.BigNumber = await providerDest.getBalance(account)
              setDestinationChainAccountBalance(destinationChainAccountNativeBalance ?? ethers.BigNumber.from(0))
            }
          }

          setEthBalanceSource(balanceSourceRelayer)
          setEthBalanceDest(balanceDestRelayer)
          setLoaded(
            !!balanceSourceRelayer && !!balanceDestRelayer && balanceSourceRelayer.gt(0) && balanceDestRelayer.gt(0)
          )
        } catch (err) {
          setError(err.message)
          setLoaded(true)
        }
      }

      // Fetch the balance when the component mounts
      if (connectedChainId) {
        fetchBalance()
      }
    }, [connectedChainId, account, bridgeProgressStep])

    useEffect(() => {
      setDestinationChainAccountBalance(ethers.BigNumber.from(0))
    }, [connectedChainId])

    return { ethBalanceSource, ethBalanceDest, destinationChainAccountBalance, error, loaded }
  }

  const {
    ethBalanceSource: relayerBalanceSource,
    ethBalanceDest: relayerBalanceDest,
    error: relayerBalanceError,
    loaded: relayerBalanceLoaded,
    destinationChainAccountBalance,
  } = useBridgeChainRelayerEthBalance()

  const selectedCurrencyBalance = useCurrencyBalance(account ?? undefined, currencies[Field.INPUT] ?? undefined)

  const [nativeBalance, setNativeBalance] = useState<ethers.BigNumber>(ethers.BigNumber.from(0))
  useEffect(() => {
    ;(async () => {
      if (provider && account) {
        const balance = await provider.getBalance(account)
        setNativeBalance(balance)
      }
    })()
  }, [provider, account, depositItemsData, activity, isShowBridgeProgress])

  const [depositableAmount, setDepositableAmount] = useState<ethers.BigNumber>(ethers.BigNumber.from(0))
  useEffect(() => {
    const bridgeChainId = BridgeChainIds[connectedChainId as SupportedL1ChainId]
    if (bridgeChainId === 0) {
      setDepositableAmount(ethers.BigNumber.from(ethers.utils.parseEther(selectedCurrencyBalance?.toExact() ?? '0')))
    } else if (bridgeChainId === 1) {
      setDepositableAmount(nativeBalance ?? ethers.BigNumber.from(0))
    }
  }, [connectedChainId, destinatinChainId, selectedCurrencyBalance, nativeBalance])

  //relayer에 최소 0.1이더씩은 있어야 브릿지 가능하고 그 이하면 알림 표시 및 UI에서 입금 불가 처리
  const isBridgeOpen =
    relayerBalanceSource?.gt(ethers.utils.parseEther('0.1')) && relayerBalanceDest?.gt(ethers.utils.parseEther('0.1'))

  const [hasSufficientBridgePool, setHasSufficientBridgePool] = useState(false) //입금입력금액이 브릿지 풀 이내 금액인지
  const [hasSufficientBalance, setHasSufficientBalance] = useState(true) //입금입력금액이 보유한 잔고 이내인지
  useEffect(() => {
    if (connectedChainId && isBridgeOpen && (destinationChainBridgeEthBalance || destinationErc20PdaBalance)) {
      const inputVal = parsedAmounts[Field.INPUT]

      // 플댑네트워크 (BridgeChainId ==1)에서 입금은 이더쪽의 genericHandler의 PDA잔고 내에서,
      // 이더쪽에서 입금은 플댑네트워크 브릿지의 nativePDA 잔고 내에서만 가능

      const isDeposiable =
        ethers.utils.parseEther(inputVal?.toExact() ?? '0').gt(0) &&
        ((BridgeChainIds[connectedChainId as SupportedL1ChainId] === 0 &&
          destinationChainBridgeEthBalance.gte(ethers.utils.parseEther(inputVal?.toExact() ?? '0'))) ||
          (BridgeChainIds[connectedChainId as SupportedL1ChainId] === 1 &&
            destinationErc20PdaBalance.gte(ethers.utils.parseEther(inputVal?.toExact() ?? '0'))))

      setHasSufficientBridgePool(isDeposiable)
      setHasSufficientBalance(ethers.utils.parseEther(inputVal?.toExact() ?? '0').lte(depositableAmount))
    }
  }, [
    connectedChainId,
    parsedAmounts[Field.INPUT],
    isBridgeOpen,
    destinationChainBridgeEthBalance,
    destinationErc20PdaBalance,
    depositableAmount,
  ])

  const selectChain = useSelectChain()
  const requestSwitchChain = useCallback(
    async (targetChainId: SupportedChainId) => {
      await selectChain(targetChainId)
    },
    [selectChain]
  )

  useEffect(() => {
    if (localStorage.getItem(`MPB_BRIDGE_LATELY_CHAIN_ID_${account}`) !== chainId?.toString()) {
      setIsShowBridgeProgress(false)
    }
  }, [account, chainId])

  const info = chainId ? getChainInfo(chainId) : undefined
  const isSupported = !!info

  useEffect(() => {
    const receiveAmount = ethers.utils
      .parseEther(parsedAmounts[Field.INPUT]?.toExact() ?? '0')
      .sub(bridgeFeeForDestinationChain)

    console.log('receiveAmount', receiveAmount.toString(), parsedAmounts[Field.INPUT]?.toExact())
    setDestinationAmount(receiveAmount)
  }, [bridgeFeeForDestinationChain, formattedAmounts[Field.INPUT]])
  // console.log('destinationAmount', destinationAmount.toString())

  const inputReceiveValue = useMemo(() => {
    const amount = ethers.utils.formatEther(destinationAmount)
    if (formattedAmounts[Field.INPUT] === '' || +destinationAmount < 0) {
      return ''
    }

    return parseFloat(amount)
  }, [formattedAmounts, destinationAmount])

  const allTransactions = useAllTransactions()
  // console.log('allTransactions', allTransactions)

  return (
    <>
      {connectedChainId && account && relayerBalanceLoaded && !isBridgeOpen && (
        <MainContentWrapper>
          <ErrorContainer>
            <ThemedText.DeprecatedBody textAlign="center">
              <div data-testid="pools-unsupported-err" style={{ color: 'orange' }}>
                PlayDapp Bridge is under maintenance at the moment. <br />
                You may try again later.
              </div>
            </ThemedText.DeprecatedBody>
          </ErrorContainer>
        </MainContentWrapper>
      )}

      <SwapWrapper chainId={chainId} className={className} id="swap-page">
        {/* <TokenSafetyModal
        isOpen={importTokensNotInDefault.length > 0 && !dismissTokenWarning}
        tokenAddress={importTokensNotInDefault[0]?.address}
        secondTokenAddress={importTokensNotInDefault[1]?.address}
        onContinue={handleConfirmTokenWarning}
        onCancel={handleDismissTokenWarning}
        showCancel={true}
      /> */}
        <SwapHeader autoSlippage={autoSlippage} chainId={chainId} />
        {showConfirm && (
          <ConfirmSwapModal
            trade={trade}
            originalTrade={tradeToConfirm}
            onAcceptChanges={handleAcceptChanges}
            txHash={txHash}
            allowedSlippage={allowedSlippage}
            //onConfirm={handleSwap}
            onConfirm={handleDeposit}
            allowance={allowance}
            swapError={swapError}
            onDismiss={handleConfirmDismiss}
            swapQuoteReceivedDate={swapQuoteReceivedDate}
            fiatValueInput={fiatValueTradeInput}
            fiatValueOutput={fiatValueTradeOutput}
            formattedAmounts={formattedAmounts}
            fee={bridgeFeeForDestinationChain}
            destinationAmount={destinationAmount}
          />
        )}
        {showPriceImpactModal && showPriceImpactWarning && (
          <PriceImpactModal
            priceImpact={largerPriceImpact}
            onDismiss={() => setShowPriceImpactModal(false)}
            onContinue={() => {
              setShowPriceImpactModal(false)
              handleContinueToReview()
            }}
          />
        )}
        <div style={{ display: 'relative' }}>
          <SwapSection>
            <Trace section={InterfaceSectionName.CURRENCY_INPUT_PANEL}>
              <div style={{ display: 'flex', alignItems: 'center', gap: '10px' }}>
                <span style={{ fontWeight: '600' }}>From</span>
                {chainId && (
                  <div style={{ display: 'flex', alignItems: 'center', gap: '6px' }}>
                    {networkToIcon(chainId)} {chainIdToNetworkName(chainId)}
                  </div>
                )}
              </div>
              <SwapCurrencyInputPanel
                error={
                  // ethers.utils.parseEther(parsedAmounts[Field.INPUT]?.toExact() ?? '0').gt(0) &&
                  // !(hasSufficientBridgePool && hasSufficientBalance)
                  formattedAmounts[Field.INPUT] !== '' && !inputReceiveValue
                }
                label={independentField === Field.OUTPUT && !showWrap ? <>From (at most)</> : <>From</>}
                disabled={disableTokenInputs}
                value={formattedAmounts[Field.INPUT]}
                showMaxButton={showMaxButton}
                currency={currencies[Field.INPUT] ?? null}
                onUserInput={handleTypeInput}
                onMax={handleMaxInput}
                fiatValue={showFiatValueInput ? fiatValueInput : undefined}
                // onCurrencySelect={handleInputSelect}
                // otherCurrency={currencies[Field.OUTPUT]}
                showCommonBases
                id={InterfaceSectionName.CURRENCY_INPUT_PANEL}
                loading={independentField === Field.OUTPUT && routeIsSyncing}
                balance={depositableAmount}
              />
            </Trace>
          </SwapSection>
          <ArrowWrapper clickable={isSupportedChain(chainId)}>
            <TraceEvent
              events={[BrowserEvent.onClick]}
              name={SwapEventName.SWAP_TOKENS_REVERSED}
              element={InterfaceElementName.SWAP_TOKENS_REVERSE_ARROW_BUTTON}
            >
              <ArrowContainer
                data-testid="swap-currency-button"
                onClick={async () => {
                  !disableTokenInputs && onSwitchTokens()
                  if (connectedChainId) {
                    await requestSwitchChain(chainPair[connectedChainId as SupportedL1ChainId])
                  }
                }}
                color={theme.textPrimary}
              >
                <ArrowDown
                  size="16"
                  color={currencies[Field.INPUT] && currencies[Field.OUTPUT] ? theme.textPrimary : theme.textTertiary}
                />
              </ArrowContainer>
            </TraceEvent>
          </ArrowWrapper>
        </div>
        <AutoColumn gap="xs">
          <div>
            <OutputSwapSection>
              <Trace section={InterfaceSectionName.CURRENCY_OUTPUT_PANEL}>
                <div style={{ display: 'flex', alignItems: 'center', gap: '10px' }}>
                  <span style={{ fontWeight: '600' }}>To</span>{' '}
                  {chainId && L1_CHAIN_IDS.some((id) => id === chainId) && (
                    <div style={{ display: 'flex', alignItems: 'center', gap: '6px' }}>
                      {networkToIcon(chainPair[chainId as SupportedL1ChainId])}
                      {chainIdToNetworkName(chainPair[chainId as SupportedL1ChainId])}
                    </div>
                  )}
                </div>
                <SwapCurrencyInputPanel
                  error={
                    // ethers.utils.parseEther(parsedAmounts[Field.INPUT]?.toExact() ?? '0').gt(0) &&
                    // !(hasSufficientBridgePool && hasSufficientBalance)
                    formattedAmounts[Field.INPUT] !== '' && !inputReceiveValue
                  }
                  // value={formattedAmounts[Field.INPUT]}
                  value={inputReceiveValue?.toString() ?? '0'}
                  disabled={disableTokenInputs}
                  onUserInput={handleTypeOutput}
                  label={independentField === Field.INPUT && !showWrap ? <>To (at least)</> : <>To</>}
                  showMaxButton={false}
                  hideBalance={false}
                  fiatValue={showFiatValueOutput ? fiatValueOutput : undefined}
                  priceImpact={stablecoinPriceImpact}
                  currency={currencies[Field.OUTPUT] ?? null}
                  onCurrencySelect={!isPlayDappNetwork ? handleOutputSelect : undefined}
                  otherCurrency={currencies[Field.OUTPUT]}
                  showCommonBases
                  id={InterfaceSectionName.CURRENCY_OUTPUT_PANEL}
                  loading={independentField === Field.OUTPUT && routeIsSyncing}
                  balance={destinationChainAccountBalance}
                  fee={bridgeFeeForDestinationChain}
                />
              </Trace>
              {recipient !== null && !showWrap ? (
                <>
                  <AutoRow justify="space-between" style={{ padding: '0 1rem' }}>
                    <ArrowWrapper clickable={false}>
                      <ArrowDown size="16" color={theme.textSecondary} />
                    </ArrowWrapper>
                    <LinkStyledButton id="remove-recipient-button" onClick={() => onChangeRecipient(null)}>
                      - Remove recipient
                    </LinkStyledButton>
                  </AutoRow>
                  <AddressInputPanel id="recipient" value={recipient} onChange={onChangeRecipient} />
                </>
              ) : null}
            </OutputSwapSection>
          </div>
          {/* {showDetailsDropdown && (
          <SwapDetailsDropdown
            trade={trade}
            syncing={routeIsSyncing}
            loading={routeIsLoading}
            allowedSlippage={allowedSlippage}
          />
        )} */}

          {isShowBridgeProgress && bridgeProgressStep && bridgeProgressStep.length > 0 && (
            <AutoColumn>
              <BridgeProgressBlock>
                <BridgeProgressHeading>#{bridgeProgressStep[0].nonce}</BridgeProgressHeading>
                <BridgeStepBlock>
                  <BridgeStepperBlock>
                    <BridgeStepNumberBlock>
                      {bridgeProgressStep[0].steps.length >= 1 ? (
                        <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 20 20" fill="none">
                          <path
                            fillRule="evenodd"
                            clipRule="evenodd"
                            d="M9.9375 1.1875C5.10501 1.1875 1.1875 5.10501 1.1875 9.9375C1.1875 14.77 5.10501 18.6875 9.9375 18.6875C14.77 18.6875 18.6875 14.77 18.6875 9.9375C18.6875 5.10501 14.77 1.1875 9.9375 1.1875ZM13.283 7.58383C13.6003 7.90113 13.6003 8.41558 13.283 8.73288L9.5197 12.4961C9.2024 12.8134 8.68795 12.8134 8.37065 12.4961L7.08786 11.2134C6.77056 10.896 6.77056 10.3816 7.08786 10.0643C7.40516 9.747 7.91961 9.747 8.23691 10.0643L8.94518 10.7726L12.1339 7.58383C12.4512 7.26653 12.9657 7.26653 13.283 7.58383Z"
                            fill="#00B6A1"
                          />
                        </svg>
                      ) : (
                        <>
                          <BridgeStepNumber
                            isActive={bridgeProgressStep[0].steps.length === 0}
                            isCompleted={bridgeProgressStep[0].steps.length >= 1}
                          />
                          <span>1</span>
                        </>
                      )}
                    </BridgeStepNumberBlock>
                    <BridgeStepperRuler
                      isActive={bridgeProgressStep[0].steps.length === 0}
                      isCompleted={bridgeProgressStep[0].steps.length >= 1}
                    />
                  </BridgeStepperBlock>
                  <BridgeStepTextBlock
                    isActive={bridgeProgressStep[0].steps.length === 0}
                    isCompleted={bridgeProgressStep[0].steps.length >= 1}
                  >
                    <h4>
                      Deposit Received
                      {bridgeProgressStep[0].depositItem?.transactionHash && (
                        <a
                          href={`${getExplorerLink(
                            connectedChainId as number,
                            bridgeProgressStep[0].depositItem?.transactionHash,
                            ExplorerDataType.TRANSACTION
                          )}`}
                          target="_blank"
                          rel="noopener noreferrer"
                        >
                          <ExternalLinkIconFeather color="#646464" size={14} />
                        </a>
                      )}
                    </h4>
                    {bridgeProgressStep[0].steps.length === 0 && (
                      <p>
                        Pending bridge from{' '}
                        {bridgeProgressStep[0].depositItem.destinationChainID === 1 ? 'Ethereum' : 'PlayDapp'} Network
                        to {bridgeProgressStep[0].depositItem.destinationChainID === 0 ? 'Ethereum' : 'PlayDapp'}{' '}
                        Network
                      </p>
                    )}
                  </BridgeStepTextBlock>
                </BridgeStepBlock>

                <BridgeStepBlock>
                  <BridgeStepperBlock>
                    <BridgeStepNumberBlock>
                      {bridgeProgressStep[0].steps.length >= 2 ? (
                        <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 20 20" fill="none">
                          <path
                            fillRule="evenodd"
                            clipRule="evenodd"
                            d="M9.9375 1.1875C5.10501 1.1875 1.1875 5.10501 1.1875 9.9375C1.1875 14.77 5.10501 18.6875 9.9375 18.6875C14.77 18.6875 18.6875 14.77 18.6875 9.9375C18.6875 5.10501 14.77 1.1875 9.9375 1.1875ZM13.283 7.58383C13.6003 7.90113 13.6003 8.41558 13.283 8.73288L9.5197 12.4961C9.2024 12.8134 8.68795 12.8134 8.37065 12.4961L7.08786 11.2134C6.77056 10.896 6.77056 10.3816 7.08786 10.0643C7.40516 9.747 7.91961 9.747 8.23691 10.0643L8.94518 10.7726L12.1339 7.58383C12.4512 7.26653 12.9657 7.26653 13.283 7.58383Z"
                            fill="#00B6A1"
                          />
                        </svg>
                      ) : (
                        <>
                          <BridgeStepNumber
                            isActive={bridgeProgressStep[0].steps.length === 1}
                            isCompleted={bridgeProgressStep[0].steps.length >= 2}
                          />
                          <span>2</span>
                        </>
                      )}
                    </BridgeStepNumberBlock>
                    <BridgeStepperRuler
                      isActive={bridgeProgressStep[0].steps.length === 1}
                      isCompleted={bridgeProgressStep[0].steps.length >= 2}
                    />
                  </BridgeStepperBlock>
                  <BridgeStepTextBlock
                    isActive={bridgeProgressStep[0].steps.length === 1}
                    isCompleted={bridgeProgressStep[0].steps.length >= 2}
                  >
                    <h4>
                      Processing{' '}
                      {bridgeProgressStep[0].proposalItemPending?.[0]?.transactionHash && (
                        <a
                          href={`${getExplorerLink(
                            targetChain as number,
                            bridgeProgressStep[0].proposalItemPending?.[0]?.transactionHash,
                            ExplorerDataType.TRANSACTION
                          )}`}
                          target="_blank"
                          rel="noopener noreferrer"
                        >
                          <ExternalLinkIconFeather color="#646464" size={14} />
                        </a>
                      )}
                    </h4>
                    {bridgeProgressStep[0].steps.length === 1 && <p>Preparing transfer PDA to PlayDapp Network</p>}
                  </BridgeStepTextBlock>
                </BridgeStepBlock>

                <BridgeStepBlock>
                  <BridgeStepperBlock>
                    <BridgeStepNumberBlock>
                      {bridgeProgressStep[0].steps.length === 3 ? (
                        <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 20 20" fill="none">
                          <path
                            fillRule="evenodd"
                            clipRule="evenodd"
                            d="M9.9375 1.1875C5.10501 1.1875 1.1875 5.10501 1.1875 9.9375C1.1875 14.77 5.10501 18.6875 9.9375 18.6875C14.77 18.6875 18.6875 14.77 18.6875 9.9375C18.6875 5.10501 14.77 1.1875 9.9375 1.1875ZM13.283 7.58383C13.6003 7.90113 13.6003 8.41558 13.283 8.73288L9.5197 12.4961C9.2024 12.8134 8.68795 12.8134 8.37065 12.4961L7.08786 11.2134C6.77056 10.896 6.77056 10.3816 7.08786 10.0643C7.40516 9.747 7.91961 9.747 8.23691 10.0643L8.94518 10.7726L12.1339 7.58383C12.4512 7.26653 12.9657 7.26653 13.283 7.58383Z"
                            fill="#00B6A1"
                          />
                        </svg>
                      ) : (
                        <>
                          <BridgeStepNumber
                            isActive={bridgeProgressStep[0].steps.length === 2}
                            isCompleted={bridgeProgressStep[0].steps.length >= 3}
                          />
                          <span>3</span>
                        </>
                      )}
                    </BridgeStepNumberBlock>
                  </BridgeStepperBlock>
                  <BridgeStepTextBlock
                    isActive={bridgeProgressStep[0].steps.length === 2}
                    isCompleted={bridgeProgressStep[0].steps.length >= 3}
                  >
                    <h4>
                      Complete{' '}
                      {bridgeProgressStep[0].proposalItemComplete?.[0]?.transactionHash && (
                        <a
                          href={`${getExplorerLink(
                            targetChain as number,
                            bridgeProgressStep[0].proposalItemComplete?.[0]?.transactionHash,
                            ExplorerDataType.TRANSACTION
                          )}`}
                          target="_blank"
                          rel="noopener noreferrer"
                        >
                          <ExternalLinkIconFeather color="#646464" size={14} />
                        </a>
                      )}
                    </h4>
                    {bridgeProgressStep[0].steps.length === 2 && (
                      <p>
                        The user receives the unlocked PDA token on the destination blockchain, interacting with a smart
                        contract or wallet. The token can then be used or held as desired.
                      </p>
                    )}
                  </BridgeStepTextBlock>
                </BridgeStepBlock>
              </BridgeProgressBlock>
            </AutoColumn>
          )}

          {showPriceImpactWarning && <PriceImpactWarning priceImpact={largerPriceImpact} />}
          <div>
            {swapIsUnsupported ? (
              <ButtonPrimary disabled={true}>
                <ThemedText.DeprecatedMain mb="4px">
                  <>Unsupported Asset</>
                </ThemedText.DeprecatedMain>
              </ButtonPrimary>
            ) : switchingChain ? (
              <ButtonPrimary disabled={true}>
                <>Connecting to {getChainInfo(switchingChain)?.label}</>
              </ButtonPrimary>
            ) : !account ? (
              <TraceEvent
                events={[BrowserEvent.onClick]}
                name={InterfaceEventName.CONNECT_WALLET_BUTTON_CLICKED}
                properties={{ received_swap_quote: getIsValidSwapQuote(trade, tradeState, swapInputError) }}
                element={InterfaceElementName.CONNECT_WALLET_BUTTON}
              >
                <ButtonPrimary onClick={toggleWalletDrawer} fontWeight={600}>
                  <>Connect Wallet</>
                </ButtonPrimary>
              </TraceEvent>
            ) : chainId && chainId !== connectedChainId ? (
              <ButtonPrimary
                onClick={async () => {
                  try {
                    await switchChain(connector, chainId)
                  } catch (error) {
                    if (didUserReject(error)) {
                      // Ignore error, which keeps the user on the previous chain.
                    } else {
                      // TODO(WEB-3306): This UX could be improved to show an error state.
                      throw error
                    }
                  }
                }}
              >
                Connect to {getChainInfo(chainId)?.label}
              </ButtonPrimary>
            ) : showWrap ? (
              <ButtonPrimary
                disabled={Boolean(wrapInputError)}
                onClick={onWrap}
                fontWeight={600}
                data-testid="wrap-button"
              >
                {wrapInputError ? (
                  <WrapErrorText wrapInputError={wrapInputError} />
                ) : wrapType === WrapType.WRAP ? (
                  <>Wrap</>
                ) : wrapType === WrapType.UNWRAP ? (
                  <>Unwrap</>
                ) : null}
              </ButtonPrimary>
            ) : (
              <TraceEvent
                events={[BrowserEvent.onClick]}
                name={SharedEventName.ELEMENT_CLICKED}
                element={InterfaceElementName.SWAP_BUTTON}
              >
                <ButtonError
                  onClick={() => {
                    //showPriceImpactWarning ? setShowPriceImpactModal(true) : handleContinueToReview()
                    handleContinueToReview()
                  }}
                  id="swap-button"
                  data-testid="swap-button"
                  //disabled={!isValid || routeIsSyncing || routeIsLoading}
                  disabled={
                    !isSupported ||
                    !isBridgeOpen ||
                    !(hasSufficientBridgePool && hasSufficientBalance && destinationAmount.gt(0))
                  }
                  error={isValid && priceImpactSeverity > 2 && allowance.state === AllowanceState.ALLOWED}
                >
                  {/*<Text fontSize={20} fontWeight={600}>*/}
                  {/*  {swapInputError ? (*/}
                  {/*    swapInputError*/}
                  {/*  ) : routeIsSyncing || routeIsLoading ? (*/}
                  {/*    <Trans>Deposit</Trans>*/}
                  {/*  ) : priceImpactSeverity > 2 ? (*/}
                  {/*    <Trans>Deposit</Trans>*/}
                  {/*  ) : (*/}
                  {/*    <Trans>Deposit</Trans>*/}
                  {/*  )}*/}
                  {/*</Text>*/}
                  <Text fontSize={20} fontWeight={600}>
                    {!isBridgeOpen ? (
                      <>Under Maintenance</>
                    ) : ethers.utils.parseEther(parsedAmounts[Field.INPUT]?.toExact() ?? '0').gt(0) &&
                      destinationAmount.lt(0) ? (
                      <>Too small deposit amount</>
                    ) : !hasSufficientBalance ? (
                      <>Insufficient Balance</>
                    ) : !hasSufficientBridgePool &&
                      ethers.utils.parseEther(parsedAmounts[Field.INPUT]?.toExact() ?? '0').gt(0) ? (
                      <>Insufficient bridge pool capacity</>
                    ) : !isSupported ? (
                      <>Wrong network</>
                    ) : (
                      <>Deposit</>
                    )}
                  </Text>
                </ButtonError>
              </TraceEvent>
            )}
          </div>
        </AutoColumn>
      </SwapWrapper>
    </>
  )
}
