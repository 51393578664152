import { formatEther } from '@ethersproject/units'
import { CurrencyAmount, Token } from '@uniswap/sdk-core'
import { useWeb3React } from '@web3-react/core'
import AnimatedDropdown from 'components/AnimatedDropdown'
import { LoadingButtonSpinner, ThemeButton } from 'components/Button'
import Column from 'components/Column'
import EthereumNetwork from 'components/Icons/EthereumNetwork'
import PlayDappNetwork from 'components/Icons/PlayDappNetwork'
import { RowBetween } from 'components/Row'
import { getConnection } from 'connection'
import { BridgeChainIds, chainPair, L1_CHAIN_IDS, SupportedChainId, SupportedL1ChainId } from 'constants/chains'
import { ethers } from 'ethers'
import { usePortfolioBalancesQuery } from 'graphql/data/__generated__/types-and-hooks'
import { GQL_MAINNET_CHAINS } from 'graphql/data/util'
import useBridgeEventQuery from 'hooks/useBridgeEventQuery'
import useSelectChain from 'hooks/useSelectChain'
import { useAtomValue } from 'jotai/utils'
import { chainIdToNetworkName } from 'lib/hooks/useCurrencyLogoURIs'
import { useProfilePageState, useSellAsset, useWalletCollections } from 'nft/hooks'
import { useIsNftClaimAvailable } from 'nft/hooks/useIsNftClaimAvailable'
import { ProfilePageStateType } from 'nft/types'
import React, { useCallback, useEffect, useReducer, useState } from 'react'
import {
  ArrowDownRight,
  ArrowRight,
  ArrowUpRight,
  ChevronDown,
  Copy,
  ExternalLink as ExternalLinkIconFeather,
  IconProps,
  Info,
  LogOut,
} from 'react-feather'
import { useNavigate } from 'react-router-dom'
import { shouldDisableNFTRoutesAtom } from 'state/application/atoms'
import { useCurrencyBalance } from 'state/connection/hooks'
import { useAppDispatch } from 'state/hooks'
import { Field } from 'state/swap/actions'
import { useDefaultsFromURLSearch, useDerivedSwapInfo } from 'state/swap/hooks'
import swapReducer, { initialState as initialSwapState } from 'state/swap/reducer'
import { updateSelectedWallet } from 'state/user/reducer'
import styled, { keyframes, useTheme } from 'styled-components/macro'
import { CopyHelper, CopyToClipboard, ExternalLink, ThemedText } from 'theme'
import { shortenAddress } from 'utils'
import { ExplorerDataType, getExplorerLink } from 'utils/getExplorerLink'

import emptyQuery from '../../assets/images/empty-query.png'
import { useCloseModal, useFiatOnrampAvailability, useOpenModal, useToggleModal } from '../../state/application/hooks'
import { ApplicationModal } from '../../state/application/reducer'
import { useUserHasAvailableClaim, useUserUnclaimedAmount } from '../../state/claim/hooks'
import { useToggleAccountDrawer } from '.'
import { IconHoverText, IconWithConfirmTextButton } from './IconButton'
import { portfolioFadeInAnimation } from './MiniPortfolio/PortfolioRow'

const AuthenticatedHeaderWrapper = styled.div`
  padding: 20px 16px;
  display: flex;
  flex-direction: column;
  flex: 1;
  overflow: auto;
  gap: 24px;
`

const HeaderButton = styled(ThemeButton)`
  border-color: transparent;
  border-radius: 12px;
  border-style: solid;
  border-width: 1px;
  height: 40px;
  margin-top: 8px;
`

const WalletButton = styled(ThemeButton)`
  border-radius: 12px;
  padding-top: 10px;
  padding-bottom: 10px;
  margin-top: 4px;
  color: white;
  border: none;
`

const UNIButton = styled(WalletButton)`
  border-radius: 12px;
  padding-top: 10px;
  padding-bottom: 10px;
  margin-top: 4px;
  color: white;
  border: none;
  background: linear-gradient(to right, #9139b0 0%, #4261d6 100%);
`

const IconContainer = styled.div`
  display: flex;
  align-items: center;
  & > a,
  & > button {
    margin-right: 8px;
  }

  & > button:last-child {
    margin-right: 0px;
    ${IconHoverText}:last-child {
      left: 0px;
    }
  }
`
const FiatOnrampNotAvailableText = styled(ThemedText.Caption)`
  align-items: center;
  color: ${({ theme }) => theme.textSecondary};
  display: flex;
  justify-content: center;
`
const FiatOnrampAvailabilityExternalLink = styled(ExternalLink)`
  align-items: center;
  display: flex;
  height: 14px;
  justify-content: center;
  margin-left: 6px;
  width: 14px;
`

const StatusWrapper = styled.div`
  display: inline-block;
  width: 70%;
  max-width: 70%;
  padding-right: 14px;
  display: inline-flex;
`

const AccountNamesWrapper = styled.div`
  overflow: hidden;
  white-space: nowrap;
  display: flex;
  width: 100%;
  flex-direction: column;
  justify-content: center;
  gap: 2px;
`

const StyledInfoIcon = styled(Info)`
  height: 12px;
  width: 12px;
  flex: 1 1 auto;
`
const StyledLoadingButtonSpinner = styled(LoadingButtonSpinner)`
  fill: ${({ theme }) => theme.accentAction};
`

const HeaderWrapper = styled.div`
  margin-bottom: 20px;
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
`

const CopyText = styled(CopyHelper).attrs({
  iconSize: 14,
  iconPosition: 'right',
})``

const FadeInColumn = styled(Column)`
  ${portfolioFadeInAnimation}
`

const PortfolioDrawerContainer = styled(Column)`
  flex: 1;
`

const CopyIcon = styled(Copy)`
  stroke: #646464;
`

const WalletStatusBlock = styled.div`
  display: flex;
  flex-direction: column;
  gap: 12px;

  h2 {
    margin: 0;
    font-size: 16px;
    font-style: normal;
    font-weight: 600;
    line-height: 150%;
  }
`

const AccountBlock = styled.div`
  display: flex;
  flex-direction: column;
  border-radius: 16px;
  border: 1px solid #ebebeb;
  background-color: #f5f5f5;
`

const AddressBox = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 12px 16px;

  p {
    font-size: 14px;
    font-style: normal;
    font-weight: 500;
    line-height: 146%;
  }
`

const AddressActionBox = styled.div`
  display: flex;
  align-items: center;
  gap: 9px;
`

const IconBox = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 8px;
  width: 32px;
  height: 32px;
  background-color: #fafafa;
  transition: all 0.2s ease-in-out;

  &:hover {
    opacity: 0.8;
  }
`

const BalanceBlock = styled.div`
  display: flex;
  flex-direction: column;
  gap: 8px;
  padding: 16px 16px 24px 16px;
`

const BalanceStatusBox = styled.div`
  display: flex;
  flex-direction: column;

  p:first-of-type {
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: 146%;
  }

  p:last-of-type {
    text-overflow: ellipsis;
    overflow: hidden;
    font-size: 20px;
    font-style: normal;
    font-weight: 700;
    line-height: 148%;
  }
`

const SwitchActionButton = styled.button`
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 4px;
  padding: 6px;
  border-radius: 999px;
  border: 1px solid #ebebeb;
  background: #fff;
  width: fit-content;
  cursor: pointer;
  transition: all 0.2s ease-in-out;

  &:hover {
    background-color: #fafafa;
  }

  p {
    font-size: 12px;
    font-style: normal;
    font-weight: 400;
    line-height: 150%;
  }
`

const HistoryBlock = styled.div`
  display: flex;
  flex-direction: column;
  gap: 12px;
`

const HistoryHeadingBlock = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  gap: 4px;

  p {
    font-size: 16px;
    font-style: normal;
    font-weight: 400;
    line-height: 150%;
    color: #8e8e8e;
  }

  & > div {
    display: flex;
    align-items: center;
    gap: 4px;
  }
`

const HistoryListBlock = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 8px;
`

const BridgeHistoryItemWrapper = styled(Column)`
  border: 1px solid ${({ theme }) => theme.backgroundOutline};
  border-radius: 16px;
  padding: 12px 16px;
  width: 100%;
`

const BridgeHistoryItemRow = styled(RowBetween)<{ open: boolean }>`
  padding: 0;
  align-items: center;
  cursor: ${({ disabled }) => (disabled ? 'initial' : 'pointer')};
`

const BridgeHistoryItemTitle = styled.div<{ isCompleted?: boolean }>`
  display: flex;
  justify-content: space-between;
  align-items: center;
  flex: 1;

  & > div {
    display: flex;
    align-items: center;
    gap: 6px;

    img {
      width: 24px;
      height: 24px;
    }

    p {
      font-size: 16px;
      font-style: normal;
      font-weight: 600;
      line-height: 150%;
    }
  }

  & > p {
    font-size: 16px;
    font-style: normal;
    font-weight: 400;
    line-height: 150%;
    color: ${({ isCompleted }) => (isCompleted ? '#00B6A1' : '#646464')};
  }
`

const HistoryEmptyBlock = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  height: 200px;
  padding: 24px 12px 16px 12px;
  gap: 16px;

  img {
    width: 54px;
  }

  p {
    font-size: 14px;
    font-weight: 400;
    line-height: 146%;
    letter-spacing: -0.028px;
    color: #8e8e8e;
  }
`

const BridgeProgressBlock = styled.div`
  display: flex;
  flex-direction: column;
  padding-top: 24px;
`

const BridgeStepBlock = styled.div`
  display: flex;
  align-items: flex-start;
  gap: 8px;
`

const BridgeStepperBlock = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
`

const BridgeStepNumberBlock = styled.div`
  display: flex;
  position: relative;

  span {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    font-size: 12px;
    font-style: normal;
    font-weight: 600;
    line-height: 150%; /* 18px */
    color: ${({ theme }) => theme.textSecondary};
  }
`

const rotateKeyframe = keyframes`
    0% {
        transform: rotate(0deg);
    }
    100% {
        transform: rotate(360deg);
    }
`

const BridgeStepNumber = styled.div<{ isActive?: boolean; isCompleted?: boolean }>`
  width: 20px;
  height: 20px;
  border: 2px solid ${({ isActive, isCompleted }) => (isActive || isCompleted ? '#00b6a1' : '#F0F0F0')};
  border-bottom-color: ${({ isActive, isCompleted }) => (isActive ? '#00b6a14c' : isCompleted ? '#00b6a1' : '#F0F0F0')};
  border-radius: 50%;
  display: inline-block;
  box-sizing: border-box;
  animation: ${rotateKeyframe} 2s linear infinite;
`

const BridgeStepperRuler = styled.div<{ isActive?: boolean; isCompleted?: boolean }>`
  width: 3px;
  min-height: 37px;
  height: auto;
  background-color: ${({ isActive, isCompleted }) => (isActive || isCompleted ? '#00b6a1' : '#F0F0F0')};
`

const BridgeStepTextBlock = styled.div<{ isActive?: boolean; isCompleted?: boolean }>`
  display: flex;
  flex-direction: column;
  gap: 6px;
  margin-top: -2px;
  width: 100%;

  h4 {
    display: flex;
    justify-content: space-between;
    align-items: flex-end;
    gap: 4px;
    margin: 0;
    font-size: 16px;
    font-weight: 400;
    line-height: 150%;
    color: ${({ isActive, isCompleted, theme }) => (isActive || isCompleted ? '#00b6a1' : theme.textPrimary)};
  }

  a {
    margin: 0;
    font-family: Inter, sans-serif;
    font-size: 14px;
    font-style: normal;
    font-weight: 500;
    line-height: 20px;
    color: #565c63;
  }

  a:hover {
    text-decoration: underline;
  }
`

const LoadMoreButton = styled.button`
  display: flex;
  height: 40px;
  padding: 16px 10px;
  justify-content: center;
  align-items: center;
  gap: 2px;
  margin-top: 16px;
  border-radius: 10px;
  border: 1px solid #ebebeb;
  background-color: #fff;
  width: fit-content;
  cursor: pointer;
  transition: all 0.2s ease-in-out;
  font-size: 14px;
  font-style: normal;
  font-weight: 600;
  line-height: 146%;

  &:hover {
    background-color: #fafafa;
  }
`

const RotatingArrow = styled(ChevronDown)<{ open?: boolean }>`
  transform: ${({ open }) => (open ? 'rotate(180deg)' : 'none')};
  transition: transform 0.1s linear;
`

export function PortfolioArrow({ change, ...rest }: { change: number } & IconProps) {
  const theme = useTheme()
  return change < 0 ? (
    <ArrowDownRight color={theme.accentCritical} size={20} {...rest} />
  ) : (
    <ArrowUpRight color={theme.accentSuccess} size={20} {...rest} />
  )
}

const LogOutCentered = styled(LogOut)`
  transform: translateX(2px);
`

export default function AuthenticatedHeader({ account, openSettings }: { account: string; openSettings: () => void }) {
  const { connector, ENSName, chainId: connectedChainId, provider } = useWeb3React()
  const dispatch = useAppDispatch()
  const navigate = useNavigate()
  const closeModal = useCloseModal()
  const setSellPageState = useProfilePageState((state) => state.setProfilePageState)
  const resetSellAssets = useSellAsset((state) => state.reset)
  const clearCollectionFilters = useWalletCollections((state) => state.clearCollectionFilters)
  const isClaimAvailable = useIsNftClaimAvailable((state) => state.isClaimAvailable)

  const shouldDisableNFTRoutes = useAtomValue(shouldDisableNFTRoutesAtom)

  const unclaimedAmount: CurrencyAmount<Token> | undefined = useUserUnclaimedAmount(account)
  const isUnclaimed = useUserHasAvailableClaim(account)
  const connection = getConnection(connector)
  const openClaimModal = useToggleModal(ApplicationModal.ADDRESS_CLAIM)
  const openNftModal = useToggleModal(ApplicationModal.UNISWAP_NFT_AIRDROP_CLAIM)
  const disconnect = useCallback(() => {
    if (connector && connector.deactivate) {
      connector.deactivate()
    }
    connector.resetState()
    dispatch(updateSelectedWallet({ wallet: undefined }))
  }, [connector, dispatch])

  const toggleWalletDrawer = useToggleAccountDrawer()

  const navigateToProfile = useCallback(() => {
    toggleWalletDrawer()
    resetSellAssets()
    setSellPageState(ProfilePageStateType.VIEWING)
    clearCollectionFilters()
    navigate('/nfts/profile')
    closeModal()
  }, [clearCollectionFilters, closeModal, navigate, resetSellAssets, setSellPageState, toggleWalletDrawer])

  const openFiatOnrampModal = useOpenModal(ApplicationModal.FIAT_ONRAMP)
  const openFoRModalWithAnalytics = useCallback(() => {
    toggleWalletDrawer()
    //sendAnalyticsEvent(InterfaceEventName.FIAT_ONRAMP_WIDGET_OPENED)
    openFiatOnrampModal()
  }, [openFiatOnrampModal, toggleWalletDrawer])

  const [shouldCheck, setShouldCheck] = useState(false)
  const {
    available: fiatOnrampAvailable,
    availabilityChecked: fiatOnrampAvailabilityChecked,
    error,
    loading: fiatOnrampAvailabilityLoading,
  } = useFiatOnrampAvailability(shouldCheck, openFoRModalWithAnalytics)

  const handleBuyCryptoClick = useCallback(() => {
    if (!fiatOnrampAvailabilityChecked) {
      setShouldCheck(true)
    } else if (fiatOnrampAvailable) {
      openFoRModalWithAnalytics()
    }
  }, [fiatOnrampAvailabilityChecked, fiatOnrampAvailable, openFoRModalWithAnalytics])
  const disableBuyCryptoButton = Boolean(
    error || (!fiatOnrampAvailable && fiatOnrampAvailabilityChecked) || fiatOnrampAvailabilityLoading
  )
  const [showFiatOnrampUnavailableTooltip, setShow] = useState<boolean>(false)
  const openFiatOnrampUnavailableTooltip = useCallback(() => setShow(true), [setShow])
  const closeFiatOnrampUnavailableTooltip = useCallback(() => setShow(false), [setShow])

  const { data: portfolioBalances } = usePortfolioBalancesQuery({
    variables: { ownerAddress: account ?? '', chains: GQL_MAINNET_CHAINS },
    fetchPolicy: 'cache-only', // PrefetchBalancesWrapper handles balance fetching/staleness; this component only reads from cache
  })

  const portfolio = portfolioBalances?.portfolios?.[0]
  const totalBalance = portfolio?.tokensTotalDenominatedValue?.value
  const absoluteChange = portfolio?.tokensTotalDenominatedValueChange?.absolute?.value
  const percentChange = portfolio?.tokensTotalDenominatedValueChange?.percentage?.value
  const [showDisconnectConfirm, setShowDisconnectConfirm] = useState(false)

  const loadedUrlParams = useDefaultsFromURLSearch()
  const prefilledState = {
    [Field.INPUT]: { currencyId: loadedUrlParams?.[Field.INPUT]?.currencyId },
    [Field.OUTPUT]: { currencyId: loadedUrlParams?.[Field.OUTPUT]?.currencyId },
  }
  const [state] = useReducer(swapReducer, { ...initialSwapState, ...prefilledState })
  const { currencies } = useDerivedSwapInfo(state, connectedChainId)

  const selectedCurrencyBalance = useCurrencyBalance(account ?? undefined, currencies[Field.INPUT] ?? undefined)

  const targetChain: SupportedL1ChainId =
    connectedChainId && L1_CHAIN_IDS.some((id) => id === connectedChainId)
      ? chainPair[connectedChainId as SupportedL1ChainId]
      : SupportedChainId.MAINNET
  const destinatinChainId = BridgeChainIds[targetChain] ?? 0

  const [nativeBalance, setNativeBalance] = useState<ethers.BigNumber>(ethers.BigNumber.from(0))
  useEffect(() => {
    ;(async () => {
      if (provider && account) {
        const balance = await provider.getBalance(account)
        setNativeBalance(balance)
      }
    })()
  }, [provider, account])

  const bridgeChainId = BridgeChainIds[connectedChainId as SupportedL1ChainId]

  const [depositableAmount, setDepositableAmount] = useState<ethers.BigNumber>(ethers.BigNumber.from(0))
  useEffect(() => {
    if (bridgeChainId === 0) {
      setDepositableAmount(ethers.BigNumber.from(ethers.utils.parseEther(selectedCurrencyBalance?.toExact() ?? '0')))
    } else if (bridgeChainId === 1) {
      setDepositableAmount(nativeBalance ?? ethers.BigNumber.from(0))
    }
  }, [connectedChainId, bridgeChainId, destinatinChainId, selectedCurrencyBalance, nativeBalance])

  const selectChain = useSelectChain()
  const requestSwitchChain = useCallback(
    async (targetChainId: SupportedChainId) => {
      await selectChain(targetChainId)
    },
    [selectChain]
  )

  const [isOpenHistoryItem, setIsOpenHistoryItem] = useState<number | null>(null)

  const handleOpenHistoryItem = (target: number) => () => {
    setIsOpenHistoryItem(isOpenHistoryItem === target ? null : target)
  }

  const { bridgeProgressStep } = useBridgeEventQuery()

  const [isLoadMore, setIsLoadMore] = useState(
    !bridgeProgressStep || (bridgeProgressStep && bridgeProgressStep.length <= 5)
  )

  return (
    <AuthenticatedHeaderWrapper>
      <WalletStatusBlock>
        <h2>Wallet</h2>

        <AccountBlock>
          <AddressBox>
            <p>{shortenAddress(account)}</p>
            <AddressActionBox>
              <CopyToClipboard toCopy={account}>
                <IconBox>
                  <CopyIcon size={16} />
                </IconBox>
              </CopyToClipboard>
              <IconWithConfirmTextButton
                data-testid="wallet-disconnect"
                onConfirm={disconnect}
                onShowConfirm={setShowDisconnectConfirm}
                Icon={LogOutCentered}
                text="Disconnect"
              />
            </AddressActionBox>
          </AddressBox>

          <BalanceBlock>
            <BalanceStatusBox>
              <p>Balance</p>
              {depositableAmount ? <p>{parseFloat((+formatEther(depositableAmount)).toFixed(6))} PDA</p> : <p>-</p>}
            </BalanceStatusBox>

            {connectedChainId && (
              <SwitchActionButton
                onClick={async () => {
                  if (connectedChainId) {
                    await requestSwitchChain(chainPair[connectedChainId as SupportedL1ChainId])
                  }
                }}
              >
                {bridgeChainId ? <PlayDappNetwork size={18} /> : <EthereumNetwork size={18} />}

                <p>{chainIdToNetworkName(connectedChainId)}</p>
                <svg xmlns="http://www.w3.org/2000/svg" width="14" height="14" viewBox="0 0 14 14" fill="none">
                  <path
                    fillRule="evenodd"
                    clipRule="evenodd"
                    d="M4.20295 1.3478C4.42506 1.56991 4.42506 1.93002 4.20295 2.15213L3.25433 3.10074H12.1188C12.4329 3.10074 12.6875 3.35538 12.6875 3.66949C12.6875 3.98361 12.4329 4.23825 12.1188 4.23825H3.25433L4.20295 5.18686C4.42506 5.40897 4.42506 5.76908 4.20295 5.99119C3.98084 6.2133 3.62073 6.2133 3.39861 5.99119L1.47908 4.07166C1.25697 3.84955 1.25697 3.48944 1.47908 3.26733L3.39861 1.3478C3.62073 1.12569 3.98084 1.12569 4.20295 1.3478ZM9.79705 7.74623C10.0192 7.52412 10.3793 7.52412 10.6014 7.74623L12.5209 9.66577C12.743 9.88788 12.743 10.248 12.5209 10.4701L10.6014 12.3896C10.3793 12.6117 10.0192 12.6117 9.79705 12.3896C9.57494 12.1675 9.57494 11.8074 9.79705 11.5853L10.7457 10.6367H1.88125C1.56714 10.6367 1.3125 10.382 1.3125 10.0679C1.3125 9.75382 1.56714 9.49918 1.88125 9.49918H10.7457L9.79705 8.55057C9.57494 8.32846 9.57494 7.96835 9.79705 7.74623Z"
                    fill="#8E8E8E"
                  />
                </svg>
              </SwitchActionButton>
            )}
          </BalanceBlock>
        </AccountBlock>
      </WalletStatusBlock>

      <HistoryBlock>
        <HistoryHeadingBlock>
          <p>Bridge History</p>
          <div>
            {bridgeChainId ? <PlayDappNetwork /> : <EthereumNetwork />}
            <ArrowRight size={14} />
            {bridgeChainId ? <EthereumNetwork /> : <PlayDappNetwork />}
          </div>
        </HistoryHeadingBlock>

        {(!bridgeProgressStep || bridgeProgressStep.length === 0) && (
          <HistoryEmptyBlock>
            <img src={emptyQuery} alt="empty query" />
            <p>There is no bridge transaction history</p>
          </HistoryEmptyBlock>
        )}

        {bridgeProgressStep && bridgeProgressStep.length > 0 && (
          <HistoryListBlock>
            {bridgeProgressStep.slice(0, isLoadMore ? bridgeProgressStep.length : 5).map((item, index) => (
              <BridgeHistoryItemWrapper key={`history-${item.nonce}`}>
                <BridgeHistoryItemRow open={isOpenHistoryItem === index} onClick={handleOpenHistoryItem(index)}>
                  <BridgeHistoryItemTitle isCompleted={item.steps.length === 3}>
                    <div>
                      <img src="https://images.playdapp.com/brand-pda.svg" alt="pda-symbol" />
                      <p>#{item.nonce}</p>
                    </div>
                    <p>
                      {(item.steps.length === 0 || item.steps.length === 1) && 'Deposit Received'}
                      {item.steps.length === 2 && 'Processing'}
                      {item.steps.length === 3 && 'Complete'}
                    </p>
                  </BridgeHistoryItemTitle>
                  <RotatingArrow open={isOpenHistoryItem === index} />
                </BridgeHistoryItemRow>
                <AnimatedDropdown open={isOpenHistoryItem === index}>
                  <div>
                    <BridgeProgressBlock>
                      <BridgeStepBlock>
                        <BridgeStepperBlock>
                          <BridgeStepNumberBlock>
                            {item.steps.length >= 1 ? (
                              <svg
                                xmlns="http://www.w3.org/2000/svg"
                                width="20"
                                height="20"
                                viewBox="0 0 20 20"
                                fill="none"
                              >
                                <path
                                  fillRule="evenodd"
                                  clipRule="evenodd"
                                  d="M9.9375 1.1875C5.10501 1.1875 1.1875 5.10501 1.1875 9.9375C1.1875 14.77 5.10501 18.6875 9.9375 18.6875C14.77 18.6875 18.6875 14.77 18.6875 9.9375C18.6875 5.10501 14.77 1.1875 9.9375 1.1875ZM13.283 7.58383C13.6003 7.90113 13.6003 8.41558 13.283 8.73288L9.5197 12.4961C9.2024 12.8134 8.68795 12.8134 8.37065 12.4961L7.08786 11.2134C6.77056 10.896 6.77056 10.3816 7.08786 10.0643C7.40516 9.747 7.91961 9.747 8.23691 10.0643L8.94518 10.7726L12.1339 7.58383C12.4512 7.26653 12.9657 7.26653 13.283 7.58383Z"
                                  fill="#00B6A1"
                                />
                              </svg>
                            ) : (
                              <>
                                <BridgeStepNumber
                                  isActive={item.steps.length === 0}
                                  isCompleted={item.steps.length >= 1}
                                />
                                <span>1</span>
                              </>
                            )}
                          </BridgeStepNumberBlock>
                          <BridgeStepperRuler isActive={item.steps.length === 0} isCompleted={item.steps.length >= 1} />
                        </BridgeStepperBlock>
                        <BridgeStepTextBlock isActive={item.steps.length === 0} isCompleted={item.steps.length >= 1}>
                          <h4>
                            <span>Deposit Received</span>
                            {item.depositItem?.transactionHash && (
                              <a
                                href={`${getExplorerLink(
                                  connectedChainId as number,
                                  item.depositItem?.transactionHash,
                                  ExplorerDataType.TRANSACTION
                                )}`}
                                target="_blank"
                                rel="noopener noreferrer"
                              >
                                <ExternalLinkIconFeather color="#646464" size={14} />
                              </a>
                            )}
                          </h4>
                        </BridgeStepTextBlock>
                      </BridgeStepBlock>

                      <BridgeStepBlock>
                        <BridgeStepperBlock>
                          <BridgeStepNumberBlock>
                            {item.steps.length >= 2 ? (
                              <svg
                                xmlns="http://www.w3.org/2000/svg"
                                width="20"
                                height="20"
                                viewBox="0 0 20 20"
                                fill="none"
                              >
                                <path
                                  fillRule="evenodd"
                                  clipRule="evenodd"
                                  d="M9.9375 1.1875C5.10501 1.1875 1.1875 5.10501 1.1875 9.9375C1.1875 14.77 5.10501 18.6875 9.9375 18.6875C14.77 18.6875 18.6875 14.77 18.6875 9.9375C18.6875 5.10501 14.77 1.1875 9.9375 1.1875ZM13.283 7.58383C13.6003 7.90113 13.6003 8.41558 13.283 8.73288L9.5197 12.4961C9.2024 12.8134 8.68795 12.8134 8.37065 12.4961L7.08786 11.2134C6.77056 10.896 6.77056 10.3816 7.08786 10.0643C7.40516 9.747 7.91961 9.747 8.23691 10.0643L8.94518 10.7726L12.1339 7.58383C12.4512 7.26653 12.9657 7.26653 13.283 7.58383Z"
                                  fill="#00B6A1"
                                />
                              </svg>
                            ) : (
                              <>
                                <BridgeStepNumber
                                  isActive={item.steps.length === 1}
                                  isCompleted={item.steps.length >= 2}
                                />
                                <span>2</span>
                              </>
                            )}
                          </BridgeStepNumberBlock>
                          <BridgeStepperRuler isActive={item.steps.length === 1} isCompleted={item.steps.length >= 2} />
                        </BridgeStepperBlock>
                        <BridgeStepTextBlock isActive={item.steps.length === 1} isCompleted={item.steps.length >= 2}>
                          <h4>
                            <span>Processing </span>
                            {item.proposalItemPending?.[0]?.transactionHash && (
                              <a
                                href={`${getExplorerLink(
                                  targetChain as number,
                                  item.proposalItemPending?.[0]?.transactionHash,
                                  ExplorerDataType.TRANSACTION
                                )}`}
                                target="_blank"
                                rel="noopener noreferrer"
                              >
                                <ExternalLinkIconFeather color="#646464" size={14} />
                              </a>
                            )}
                          </h4>
                        </BridgeStepTextBlock>
                      </BridgeStepBlock>

                      <BridgeStepBlock>
                        <BridgeStepperBlock>
                          <BridgeStepNumberBlock>
                            {item.steps.length === 3 ? (
                              <svg
                                xmlns="http://www.w3.org/2000/svg"
                                width="20"
                                height="20"
                                viewBox="0 0 20 20"
                                fill="none"
                              >
                                <path
                                  fillRule="evenodd"
                                  clipRule="evenodd"
                                  d="M9.9375 1.1875C5.10501 1.1875 1.1875 5.10501 1.1875 9.9375C1.1875 14.77 5.10501 18.6875 9.9375 18.6875C14.77 18.6875 18.6875 14.77 18.6875 9.9375C18.6875 5.10501 14.77 1.1875 9.9375 1.1875ZM13.283 7.58383C13.6003 7.90113 13.6003 8.41558 13.283 8.73288L9.5197 12.4961C9.2024 12.8134 8.68795 12.8134 8.37065 12.4961L7.08786 11.2134C6.77056 10.896 6.77056 10.3816 7.08786 10.0643C7.40516 9.747 7.91961 9.747 8.23691 10.0643L8.94518 10.7726L12.1339 7.58383C12.4512 7.26653 12.9657 7.26653 13.283 7.58383Z"
                                  fill="#00B6A1"
                                />
                              </svg>
                            ) : (
                              <>
                                <BridgeStepNumber
                                  isActive={item.steps.length === 2}
                                  isCompleted={item.steps.length >= 3}
                                />
                                <span>3</span>
                              </>
                            )}
                          </BridgeStepNumberBlock>
                        </BridgeStepperBlock>
                        <BridgeStepTextBlock isActive={item.steps.length === 2} isCompleted={item.steps.length >= 3}>
                          <h4>
                            <span>Complete </span>
                            {item.proposalItemComplete?.[0]?.transactionHash && (
                              <a
                                href={`${getExplorerLink(
                                  targetChain as number,
                                  item.proposalItemComplete?.[0]?.transactionHash,
                                  ExplorerDataType.TRANSACTION
                                )}`}
                                target="_blank"
                                rel="noopener noreferrer"
                              >
                                <ExternalLinkIconFeather color="#646464" size={14} />
                              </a>
                            )}
                          </h4>
                        </BridgeStepTextBlock>
                      </BridgeStepBlock>
                    </BridgeProgressBlock>
                  </div>
                </AnimatedDropdown>
              </BridgeHistoryItemWrapper>
            ))}
            {!isLoadMore && <LoadMoreButton onClick={() => setIsLoadMore(true)}>+ View More</LoadMoreButton>}
          </HistoryListBlock>
        )}
      </HistoryBlock>

      {/*<HeaderWrapper>*/}
      {/*  <StatusWrapper>*/}
      {/*    <StatusIcon account={account} connection={connection} size={40} />*/}
      {/*    {account && (*/}
      {/*      <AccountNamesWrapper>*/}
      {/*        <ThemedText.SubHeader>*/}
      {/*          <CopyText toCopy={ENSName ?? account}>{ENSName ?? shortenAddress(account)}</CopyText>*/}
      {/*        </ThemedText.SubHeader>*/}
      {/*        /!* Displays smaller view of account if ENS name was rendered above *!/*/}
      {/*        {ENSName && (*/}
      {/*          <ThemedText.BodySmall color="textTertiary">*/}
      {/*            <CopyText toCopy={account}>{shortenAddress(account)}</CopyText>*/}
      {/*          </ThemedText.BodySmall>*/}
      {/*        )}*/}
      {/*      </AccountNamesWrapper>*/}
      {/*    )}*/}
      {/*  </StatusWrapper>*/}
      {/*  <IconContainer>*/}
      {/*    /!* {!showDisconnectConfirm && (*/}
      {/*      <IconButton data-testid="wallet-settings" onClick={openSettings} Icon={Settings} />*/}
      {/*    )} *!/*/}
      {/*    <TraceEvent*/}
      {/*      events={[BrowserEvent.onClick]}*/}
      {/*      name={SharedEventName.ELEMENT_CLICKED}*/}
      {/*      element={InterfaceElementName.DISCONNECT_WALLET_BUTTON}*/}
      {/*    >*/}
      {/*      <IconWithConfirmTextButton*/}
      {/*        data-testid="wallet-disconnect"*/}
      {/*        onConfirm={disconnect}*/}
      {/*        onShowConfirm={setShowDisconnectConfirm}*/}
      {/*        Icon={LogOutCentered}*/}
      {/*        text="Disconnect"*/}
      {/*      />*/}
      {/*    </TraceEvent>*/}
      {/*  </IconContainer>*/}
      {/*</HeaderWrapper>*/}
      {/* <PortfolioDrawerContainer>
        {totalBalance !== undefined ? (
          <FadeInColumn gap="xs">
            <ThemedText.HeadlineLarge fontWeight={500} data-testid="portfolio-total-balance">
              {formatNumber(totalBalance, NumberType.PortfolioBalance)}
            </ThemedText.HeadlineLarge>
            <AutoRow marginBottom="20px">
              {absoluteChange !== 0 && percentChange && (
                <>
                  <PortfolioArrow change={absoluteChange as number} />
                  <ThemedText.BodySecondary>
                    {`${formatNumber(Math.abs(absoluteChange as number), NumberType.PortfolioBalance)} (${formatDelta(
                      percentChange
                    )})`}
                  </ThemedText.BodySecondary>
                </>
              )}
            </AutoRow>
          </FadeInColumn>
        ) : (
          <Column gap="xs">
            <LoadingBubble height="44px" width="170px" />
            <LoadingBubble height="16px" width="100px" margin="4px 0 20px 0" />
          </Column>
        )}
        {!shouldDisableNFTRoutes && (
          <HeaderButton
            data-testid="nft-view-self-nfts"
            onClick={navigateToProfile}
            size={ButtonSize.medium}
            emphasis={ButtonEmphasis.medium}
          >
            <Trans>View and sell NFTs</Trans>
          </HeaderButton>
        )}
        <HeaderButton
          size={ButtonSize.medium}
          emphasis={ButtonEmphasis.medium}
          onClick={handleBuyCryptoClick}
          disabled={disableBuyCryptoButton}
          data-testid="wallet-buy-crypto"
        >
          {error ? (
            <ThemedText.BodyPrimary>{error}</ThemedText.BodyPrimary>
          ) : (
            <>
              {fiatOnrampAvailabilityLoading ? (
                <StyledLoadingButtonSpinner />
              ) : (
                <CreditCard height="20px" width="20px" />
              )}{' '}
              <Trans>Buy crypto</Trans>
            </>
          )}
        </HeaderButton>
        {Boolean(!fiatOnrampAvailable && fiatOnrampAvailabilityChecked) && (
          <FiatOnrampNotAvailableText marginTop="8px">
            <Trans>Not available in your region</Trans>
            <Tooltip
              show={showFiatOnrampUnavailableTooltip}
              text={<Trans>Moonpay is not available in some regions. Click to learn more.</Trans>}
            >
              <FiatOnrampAvailabilityExternalLink
                onMouseEnter={openFiatOnrampUnavailableTooltip}
                onMouseLeave={closeFiatOnrampUnavailableTooltip}
                style={{ color: 'inherit' }}
                href="https://support.uniswap.org/hc/en-us/articles/11306664890381-Why-isn-t-MoonPay-available-in-my-region-"
              >
                <StyledInfoIcon />
              </FiatOnrampAvailabilityExternalLink>
            </Tooltip>
          </FiatOnrampNotAvailableText>
        )}
        <MiniPortfolio account={account} />
        {isUnclaimed && (
          <UNIButton onClick={openClaimModal} size={ButtonSize.medium} emphasis={ButtonEmphasis.medium}>
            <Trans>Claim</Trans> {unclaimedAmount?.toFixed(0, { groupSeparator: ',' } ?? '-')} <Trans>reward</Trans>
          </UNIButton>
        )}
        {isClaimAvailable && (
          <UNIButton size={ButtonSize.medium} emphasis={ButtonEmphasis.medium} onClick={openNftModal}>
            <Trans>Claim Uniswap NFT Airdrop</Trans>
          </UNIButton>
        )}
      </PortfolioDrawerContainer> */}
    </AuthenticatedHeaderWrapper>
  )
}
