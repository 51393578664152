import { useEffect, useState } from 'react'
import { ApolloClient, useQuery } from '@apollo/client'
import { GraphClientByChain } from 'graphql/data/apollo'
import { chainPair, SupportedL1ChainId } from 'constants/chains'
import { DEPOSIT_ITEMS, PROPOSAL_EVENTS } from 'query/bridge-status'
import { useWeb3React } from '@web3-react/core'

type BridgeProgressStatus = {
  nonce: number
  steps: { status: number; transactionHash: string }[]
  depositItem: {
    destinationChainID: number
    transactionHash: string
  }
  proposalItemPending: { transactionHash: string }[]
  proposalItemComplete: { transactionHash: string }[]
}

export default function useBridgeEventQuery() {

  const {account,chainId:connectedChainId} = useWeb3React()

  const [sourceChainGraphClient, setSourceChainGraphClient] = useState<ApolloClient<any> | undefined>(undefined)
  const [destChainGraphClient, setDestChainGraphClient] = useState<ApolloClient<any> | undefined>(undefined)

  useEffect(() => {
    if (connectedChainId) {
      setSourceChainGraphClient(GraphClientByChain[connectedChainId as SupportedL1ChainId])
      setDestChainGraphClient(
        GraphClientByChain[chainPair[connectedChainId as SupportedL1ChainId] as SupportedL1ChainId]
      )
    }
  }, [connectedChainId])

  const { data: depositItemsData, startPolling: startDepositPolling } = useQuery(DEPOSIT_ITEMS, {
    variables: { id: account },
    client: sourceChainGraphClient,
    skip: !sourceChainGraphClient,
  })

  const { data: proposalEventsData, startPolling: startProposalPolling } = useQuery(PROPOSAL_EVENTS, {
    variables: { id: account },
    client: destChainGraphClient,
    skip: !destChainGraphClient,
  })

  const [bridgeProgressStep, setBridgeProgressStep] = useState<BridgeProgressStatus[] | null>(null)

  useEffect(() => {
    if (
      depositItemsData &&
      Array.isArray(depositItemsData?.depositItems) &&
      depositItemsData?.depositItems.length > 0 &&
      proposalEventsData &&
      Array.isArray(proposalEventsData?.proposalEvents) &&
      proposalEventsData?.proposalEvents.length > 0
    ) {
      const steps = depositItemsData?.depositItems.reduce(
        (acc: BridgeProgressStatus[], cur: { depositNonce: number }) => {
          const nonce = cur.depositNonce as number
          const filteredProposalEvents = proposalEventsData?.proposalEvents.filter(
            (value: { depositNonce: number }) => value.depositNonce === nonce
          )
          return [
            ...acc,
            {
              nonce,
              steps: filteredProposalEvents,
              depositItem: cur,
              proposalItemPending: filteredProposalEvents.filter((e: { status: number }) => e.status === 1),
              proposalItemComplete: filteredProposalEvents.filter((e: { status: number }) => e.status === 3),
            },
          ]
        },
        []
      )

      setBridgeProgressStep(steps)
    }
  }, [depositItemsData, proposalEventsData])

  useEffect(() => {
    startDepositPolling(5000)
    startProposalPolling(5000)
  }, [startDepositPolling, startProposalPolling, sourceChainGraphClient, destChainGraphClient])

  return {
    depositItemsData,
    proposalEventsData,
    bridgeProgressStep,
  }
}
