import 'components/analytics'

// Dump some metadata into the window to allow client verification.
window.GIT_COMMIT_HASH = process.env.REACT_APP_GIT_COMMIT_HASH

// Actual KEYs are set by proxy servers.
const AMPLITUDE_DUMMY_KEY = '00000000000000000000000000000000'
export const STATSIG_DUMMY_KEY = 'client-0000000000000000000000000000000000000000000'

// Sentry.init({
//   dsn: process.env.REACT_APP_SENTRY_DSN,
//   release: process.env.REACT_APP_GIT_COMMIT_HASH,
//   environment: getEnvName(),
//   enabled: isSentryEnabled(),
//   tracesSampleRate: Number(process.env.REACT_APP_SENTRY_TRACES_SAMPLE_RATE ?? 0),
//   integrations: [
//     new BrowserTracing({
//       startTransactionOnLocationChange: false,
//       startTransactionOnPageLoad: true,
//     }),
//   ],
//   beforeSend,
// })

// initializeAnalytics(AMPLITUDE_DUMMY_KEY, OriginApplication.INTERFACE, {
//   proxyUrl: process.env.REACT_APP_AMPLITUDE_PROXY_URL,
//   defaultEventName: SharedEventName.PAGE_VIEWED,
//   commitHash: process.env.REACT_APP_GIT_COMMIT_HASH,
//   isProductionEnv: isProductionEnv(),
// })
