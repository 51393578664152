import { Trans } from '@lingui/macro'
import { Percent } from '@uniswap/sdk-core'
import { useWeb3React } from '@web3-react/core'
import Column, { AutoColumn } from 'components/Column'
import { chainPair, SupportedChainId, SupportedL1ChainId } from 'constants/chains'
import { ethers } from 'ethers'
import { InterfaceTrade } from 'state/routing/types'
import { Field } from 'state/swap/actions'
import styled from 'styled-components/macro'
import { Divider } from 'theme'

import { SwapModalHeaderAmount } from './SwapModalHeaderAmount'

const Rule = styled(Divider)`
  margin: 16px 2px 24px 2px;
`

const HeaderContainer = styled(AutoColumn)`
  margin-top: 24px;
`

function chainIdToCurrencyName(networkId: number | undefined) {
  if (!networkId) return 'Ethereum'
  switch (networkId) {
    case SupportedChainId.MAINNET:
      return 'Ethereum'
    case SupportedChainId.SEPOLIA:
      return 'Sepolia'
    case SupportedChainId.PLAYDAPP_TESTNET:
      return 'PlayDapp'
    case SupportedChainId.PLAYDAPP_MAINNET:
      return 'PlayDapp'
    default:
      return 'Ethereum'
  }
}

export default function SwapModalHeader({
  trade,
  allowedSlippage,
  formattedAmounts,
  fee,
  destinationAmount,
}: {
  trade?: InterfaceTrade
  allowedSlippage: Percent
  formattedAmounts?: { [x: string]: string }
  fee?: ethers.BigNumber
  destinationAmount?: ethers.BigNumber
}) {
  // const fiatValueInput = useUSDPrice(trade.inputAmount)
  // const fiatValueOutput = useUSDPrice(trade.outputAmount)

  const { chainId } = useWeb3React()

  return (
    <HeaderContainer gap="sm">
      <Column gap="md">
        <SwapModalHeaderAmount
          field={Field.INPUT}
          label={<Trans>You will pay</Trans>}
          amount={+(formattedAmounts?.INPUT || 0)}
          network={chainIdToCurrencyName(chainId)}
          fee={fee}
        />
        <SwapModalHeaderAmount
          field={Field.OUTPUT}
          label={<Trans>You will receive</Trans>}
          // amount={+(formattedAmounts?.INPUT || 0)}
          amount={Number(ethers.utils.formatEther(destinationAmount?.toString() ?? '0')) || 0}
          network={chainIdToCurrencyName(chainPair[chainId as SupportedL1ChainId])}

          // tooltipText={
          //   trade.tradeType === TradeType.EXACT_INPUT ? (
          //     <ThemedText.Caption>
          //       <Trans>
          //         Output is estimated. You will receive at least{' '}
          //         <b>
          //           {trade.minimumAmountOut(allowedSlippage).toSignificant(6)} {trade.outputAmount.currency.symbol}
          //         </b>{' '}
          //         or the transaction will revert.
          //       </Trans>
          //     </ThemedText.Caption>
          //   ) : (
          //     <ThemedText.Caption>
          //       <Trans>
          //         Input is estimated. You will sell at most{' '}
          //         <b>
          //           {trade.maximumAmountIn(allowedSlippage).toSignificant(6)} {trade.inputAmount.currency.symbol}
          //         </b>{' '}
          //         or the transaction will revert.
          //       </Trans>
          //     </ThemedText.Caption>
          //   )
          // }
        />
      </Column>
      <Rule />
    </HeaderContainer>
  )
}
