import * as React from 'react'
import { SVGProps } from 'react'

const SVGComponent = (props: SVGProps<SVGSVGElement> & { size?: number }) => (
  <svg
    width={props.size ?? 16}
    height={props.size ?? 16}
    viewBox="0 0 16 16"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      d="M8 16C12.4183 16 16 12.4183 16 8C16 3.58172 12.4183 0 8 0C3.58172 0 0 3.58172 0 8C0 12.4183 3.58172 16 8 16Z"
      fill="#F0F0F2"
    />
    <path
      d="M8 16C12.4183 16 16 12.4183 16 8C16 3.58172 12.4183 0 8 0C3.58172 0 0 3.58172 0 8C0 12.4183 3.58172 16 8 16Z"
      fill="#F0F0F2"
    />
    <path d="M7.92136 2.43457L7.84692 2.68709V10.0102L7.92136 10.0847L11.321 8.07422L7.92136 2.43457Z" fill="#343434" />
    <path d="M7.92138 2.43457L4.52173 8.07561L7.92138 10.0854V6.53057V2.43457Z" fill="#8C8C8C" />
    <path d="M7.92138 10.7291L7.87964 10.7798V13.3885L7.92138 13.511L11.3231 8.7207L7.92138 10.7291Z" fill="#3C3C3B" />
    <path d="M7.92138 13.5103V10.7277L4.52173 8.7207L7.92138 13.5103Z" fill="#8C8C8C" />
    <path d="M7.92151 10.0851L11.3212 8.07462L7.92151 6.53027V10.0851Z" fill="#141414" />
    <path d="M4.52173 8.07532L7.92138 10.0851V6.53027L4.52173 8.07532Z" fill="#393939" />
  </svg>
)
export default SVGComponent
